<ng2-toasty></ng2-toasty>
<div class="jumbotron" style="margin-bottom: 0;" id="InverntryPge">

  <br /><br />
  <main class="content-wrapper" id="closed">

    <div *ngIf="Inverntry == false && LoginDes == true"> 
      <section class="ftco-section" id="login">
        <div class="container">

          <div class="row justify-content-center">
            <div class="col-md-12 col-lg-10">
              <div class="wrap d-md-flex">
                <div class="img" style="background-image: url(../../assets/images/360_F_278043545.jpg);">
                </div>
                <div class="login-wrap p-4 p-md-5">
                  <div class="d-flex">
                    <div class="w-100">
                      <h3 class="mb-4">Sign In</h3>
                    </div>

                  </div>
                  <form action="#" class="signin-form">
                    <div class="form-group mb-3">
                      <label class="label" for="name">Username <span class="star"> *</span></label>
                      <input type="text" [(ngModel)]="_email" [ngModelOptions]="{standalone: true}" class="form-control"
                        placeholder="Username" required>
                    </div>
                    <div class="form-group mb-3">
                      <label class="label" for="password">Password <span class="star"> *</span></label>
                      <input type="password" class="form-control" [(ngModel)]="_password"
                        [ngModelOptions]="{standalone: true}" placeholder="Password" required>
                    </div>
                    <div class="form-group">
                      <button type="button" class="form-control btn btn-primary rounded submit px-3"
                        (click)="login()">Sign In</button>
                    </div>
                    <div class="form-group d-md-flex">
                      <div class="w-50 text-left">
                        <!-- <label class="checkbox-wrap checkbox-primary mb-0">Remember Me
                          <input type="checkbox" >
                          <span class="checkmark"></span>
                        </label> -->
                      </div>
                      <div class="w-50 text-md-right">
                        <a (click)="forgotPassword()" style="color: #00B98E; font-weight: 500;text-decoration: underline; cursor: pointer;">Forgot Password</a>
                      </div>
                    </div>
                  </form>
                  <!-- <p class="text-center">Not a member? <a data-toggle="tab" href="#signup">Sign Up</a></p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div *ngIf="Inverntry == true && LoginDes == false">
      <section class="shop-checkouts spacegiven" id="inventory" style="padding: 10px 15px;">

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h4 style="margin: 0;padding: 0;" class="page-title">Inventory Details<span (click)="logout()" style="text-align: right;font-size:18px;cursor: pointer;"  class="ONBlink">Logout </span></h4>
            
          </div>
        
        </div>
        <hr>
        <div class="row justify-content-md-center">
          <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div class="my_profile_setting_input form-group">
              <label style="width: 100%;">Property Name  <span class="star"> *</span> <span (click)="FnpropertyDtlsUpdate()"  class="ONBlink">Update Property Details <i class="pi pi-external-link"></i> </span></label><br />

              <div *ngIf="property">

                <input type="text" id="propertyname" name="propertyname" class="form-control" [(ngModel)]="propertyname"
                  placeholder="Property Name" readonly="true" style="height: 36px;">

              </div>
              <div *ngIf="masterproperty">

                <select class="form-control" data-placeholder="Choose a Property Name"
                  (change)="onChange($event.target.value)" style="height: 36px;">
                  <option value="">Choose a Property Name</option>
                  <option *ngFor="let Property of Properties" value={{Property.value}}>
                    {{Property.label}}
                  </option>
                </select>

               
              </div>
            </div>
          </div>

          <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">

                <div class="row calendarBdr">

                  <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div class="my_profile_setting_input form-group">
                      <label>From Date <span class="star"> *</span></label><br />

                      <p-calendar placeholder="From Date" [(ngModel)]="FromDt" dateFormat="dd/mm/yy"
                        showButtonBar="true" (onSelect)="onSelect(FromDt)" [readonlyInput]="true" dateFormat="dd/mm/yy"
                        [showIcon]="true"></p-calendar>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div class="my_profile_setting_input form-group">
                      <label>To Date <span class="star"> *</span> </label><br />
                      <p-calendar placeholder="To Date" [(ngModel)]="ToDt" [maxDate]="maxDate" [minDate]="minDate"
                        showButtonBar="true" [readonlyInput]="true" dateFormat="dd/mm/yy" [showIcon]="true"
                        (onSelect)="onSelect1(ToDt)" disabled="true"></p-calendar>
                    </div>
                  </div>
                </div>
              </div>




              <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                <label class="mobhde">&nbsp;</label>
                <br class="mobhde">
                <button type="button" class="form-control btn btn-primary rounded submit"
                (click)="FnSearch()" style="width: auto;height: 36px;padding: 0 10px; font-weight: 500;">Show Inventory</button> &nbsp;&nbsp;
                <button type="button" class="form-control btn btn-primary rounded submit"
                (click)="Assign()" style="width: auto;height: 36px;padding: 0 10px; font-weight: 500;">Update Inventory</button> 

              </div>


            </div>
          </div>


        </div>


        <div class="row tblAvailability">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div style="margin: 0 auto;">
              <div style="text-align: center;" [innerHTML]="htmltextModelNew1">
            </div>
          </div>
        </div>
      </div>



        <div class="row tblAvailability">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div style="margin: 0 auto;">
              <div [innerHTML]="htmltextModelNew" role="region" aria-labelledby="HeadersCol" tabindex="0"
                class="rowheaders Cntalign">
              </div>
            </div>
          </div>
        </div>










      </section>
    </div>


  </main>
</div>

<script>
  // JavaScript to handle scrolling behavior
  const table = document.getElementById('myTable');
  const firstColumn = document.querySelectorAll('.freeze-column');

  table.addEventListener('scroll', function () {
    const topOffset = this.scrollTop;
    const leftOffset = this.scrollLeft;

    firstColumn.forEach((cell, index) => {
      cell.style.transform = `translateY(${topOffset}px)`;
      if (index === 0) {
        cell.style.transform += ` translateX(${leftOffset}px)`;
      }
    });
  });
</script>


<p-dialog header="Update Availability" [(visible)]="displayDialog2" [responsive]="true" showEffect="fade" [modal]="true"
  [closable]="false">
  <div class="ui-grid ui-grid-responsive ui-fluid">
    <div class="form-material form-horizontal">
      <div class="ui-grid-row">
        <div class="ui-grid-col-6">
          <div class="form-group mgnrht">
            <label for="PFromDt" style="margin-bottom: 0.1rem;">Date </label><br />
            <input pInputText [(ngModel)]="EdtDate" style="padding-left: 10px; height: 36px;"
              class="form-control" disabled readonlyInput="true" />
          </div>
        </div>
        <div class="ui-grid-col-6">
          <div class="form-group">
            <label for="RoomType" style="margin-bottom: 0.1rem;">Room Type</label><br />
            <input pInputText [(ngModel)]="EdtRoomType"
              style="padding-left: 10px;height: 36px;" class="form-control" disabled
              readonlyInput="true" />
          </div>
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-grid-col-6">
          <div class="form-group mgnrht">
            <label for="BookingCount" style="margin-bottom: 0.1rem;">Booking Count</label><br />
            <input pInputText [(ngModel)]="EdtBookingCount"
              style="padding-left: 10px;height: 36px;" class="form-control" disabled
              readonlyInput="true" />
          </div>
        </div>
        <div class="ui-grid-col-6">
          <div class="form-group">
            <label for="RoomCount" style="margin-bottom: 0.1rem;">Room Count<span class="red"> *</span></label><br />
            <input pInputText [(ngModel)]="EdtRoomCount" [disabled]="PastDte" maxlength="2"   pKeyFilter="int" appNoMinus
              style="padding-left: 10px;height: 36px;" class="form-control" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix" style="text-align: center;">
      <button style="display: none;padding: 0.375rem 0.75rem;" type="button" class="btn btn-primary" (click)="fnEdtUpdate()" disabled>
        <span class="btn-label">
          <i class="fa fa-close"></i>&nbsp; 
        </span>Close</button>

      <button type="button" class="btn btn-primary" style="float: none;padding: 0.375rem 0.75rem;" (click)="fnEdtUpdate()" [disabled]="PastDte">
        <span class="btn-label">
          <i class="fa fa-check"></i>&nbsp; 
        </span>Update</button>

      <button type="button" class="btn btn-danger" style="float: none;padding: 0.375rem 0.75rem;" (click)="fnclose2()">
        <span class="btn-label">
          <i class="fa fa-close"></i>&nbsp;
        </span>Close</button>
    </div>
  </p-footer>
</p-dialog>



<p-dialog header="Assign Availability" [(visible)]="displayDialog1" [responsive]="true" showEffect="fade" [modal]="true" [closable]="false" class="AsgnAvailability">
    <div class="ui-grid ui-grid-responsive ui-fluid" style="overflow: visible;">
            <div class="ui-grid-row AsgnAvailability1">
                <div class="ui-grid-col-6">
                    <div class="form-group mgnrht">
                        <label for="PFromDt" style="margin-bottom: 0.1rem;">From Date<span class="red"> *</span></label><br />
                        <p-calendar tabindex="-1" [(ngModel)]="PFromDt" dateFormat="dd/mm/yy"  (onSelect)="onSelect2($event)" readonlyInput="true" [minDate]="minDate3"  [maxDate]="maxDate3"  [showIcon]="true"></p-calendar>
                    </div>
                </div>
                <div class="ui-grid-col-6">
                    <div class="form-group">
                        <label for="PToDt" style="margin-bottom: 0.1rem;">To Date<span class="red"> *</span></label><br />
                        <p-calendar [(ngModel)]="PToDt"   dateFormat="dd/mm/yy" readonlyInput="true"   [minDate]="minDate2" [maxDate]="maxDate2"  [showIcon]="true" ></p-calendar>
                    </div>
                </div>
            </div>

             <div class="ui-grid-row">
              <div class="ui-grid-col-12">
                <br/>
                <div style="border: 1.5px solid #ebebeb;padding: 10px;" class="SelDays"> 
                  <label for="PToDt" style="margin-left: 10px; margin-bottom: 0.7rem;display: block; margin-top: -21px; background-color: #FFF; width: 90px;"> Select days <span class="red"> * </span></label>
                <p-selectButton [options]="types" [(ngModel)]="selectedTypes" multiple="multiple"></p-selectButton>
                  <br/>
                  <p  style="margin: 10px 0 5px 0; width: 275px; word-break: break-all;">Selected days: <b>{{selectedTypes}}</b></p> 
                </div>



                <br/>

              </div>
            </div>  




           <div class="form-material"> 
            <div class="ui-grid-row">
                <div class="ui-grid-col-6">
                    <div class="form-group mgnrht">
                        <label for="RoomType" style="margin-bottom: 0.1rem;">Room Type<span class="red"> *</span></label><br />
                        <select style="width: 100%;height: 36px;" class="form-control" data-placeholder="Choose a Room Type" [(ngModel)]="RoomType">
                            <option value="">Select</option>
                            <option *ngFor="let Type of RoomTyprArray" value={{Type.RoomType}}>
                                {{Type.RoomType}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="ui-grid-col-6">
                    <div class="form-group" >
                        <label for="AsgnRoomCount" style="margin-bottom: 0.1rem;">Room Count<span class="red"> *</span></label><br />
                        <input pInputText style="width: 100%;height: 36px;" class="form-control input-sm" [(ngModel)]="AsgnRoomCount"  maxlength="2" pKeyFilter="int" appNoMinus />
                    </div>
                </div>
            </div>
        </div> 
       


    </div>

    <p-footer>
      <div class="" style="text-align: center;">
      
              <button type="button" class="btn btn-primary" style="float: none; display: none;padding: 0.375rem 0.75rem;" (click)="fnAssign()"  >
                      <span class="btn-label">
                          <i class="fa fa-check"></i>&nbsp;
                      </span>Assign</button>

          <button type="button" class="btn btn-primary" style="float: none;padding: 0.375rem 0.75rem;" (click)="fnAssign()"  [disabled]="PastDte" >
              <span class="btn-label">
                  <i class="fa fa-check"></i>&nbsp;
              </span>Assign</button>

          <button type="button" class="btn btn-danger" style="float: none;padding: 0.375rem 0.75rem;" (click)="fnclose()">
              <span class="btn-label">
                  <i class="fa fa-close"></i>&nbsp;
              </span>Close</button>   

      </div>
      </p-footer>



    
</p-dialog>