<ng2-toasty></ng2-toasty>
<div class="jumbotron">


    <main class="content-wrapper" id="closed">
        <div class="container-fluid">
            <div class="main_content_container">

                <br />

                <div class="container" id="GSTHeader">
                    <br />
                    <div class="row ">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <section class="shop-checkouts spacegiven" style="min-height: 165px;">
                                <div style="margin-left: 5%;">
                                    <label style="font-weight: 600;"> &nbsp;&nbsp;&nbsp;</label>&nbsp;&nbsp; <label
                                        class="colors">{{HotelName}}</label><br />
                                    <label style="font-weight: 600;">GST Number </label>&nbsp;: <label
                                        class="ClrGst">{{HotelGstNumber}}</label><br />
                                    <label style="font-weight: 600;">Address &nbsp;: <span
                                            class="ClrAdress">{{HotelAddress}}</span></label>
                                </div>
                            </section>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <section class="shop-checkouts spacegiven" style="min-height: 165px;">
                                <div style="margin-left: 5%;">
                                    <label style="font-weight: 600;">&nbsp;&nbsp; &nbsp;</label>&nbsp; <label
                                        class="colors"> {{HBName}}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                                            id="countdown"></span></label><br />
                                    <label style="font-weight: 600;">GST Number
                                    </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <label class="ClrGst">
                                        {{HBGstNumber}}</label><br />
                                    <label style="font-weight: 600;">Address : <span
                                            class="ClrAdress">{{HBAddress}}</span></label>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <br />

                <!-- <section class="shop-checkouts spacegiven inputHeight">
                    <div class="container">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" data-title="Welcome"
                            data-intro="Fill your Invoice Details & Upload Invoice." data-position="right">
                            <h4>Invoice Details</h4>
                            <hr />
                            <div class="row">
                                 
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div class="my_profile_setting_input form-group">
                                        <label>Invoice No <span class="star"> *</span></label>
                                        <input type="text" placeholder="Invoice No" class="form-control"
                                            name="InvoiceNo" [(ngModel)]="InvoiceNo">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div class="my_profile_setting_input form-group CalHeight">
                                        <label>Invoice Date <span class="star"> *</span></label><br />
                                        <p-calendar [style]="{'width':'100%'}"  [maxDate]="EndDate" [(ngModel)]="InvoiceDate"
                                            placeholder="Invoice Date" showButtonBar="true" [readonlyInput]="true"
                                            dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
                                    </div>
                                </div>

                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                    <div class="my_profile_setting_input form-group">
                                        <label>No.of Stay Days<span class="star"> *</span></label>
                                        <input type="text" class="form-control" placeholder="No.of Stay Days"
                                            maxlength="2" pKeyFilter="int" name="NoofDays" [(ngModel)]="NoofDays">
                                    </div>
                                </div>

                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                    <div class="my_profile_setting_input form-group">
                                        <label>Submitted On</label>
                                        <input type="text" [(ngModel)]="SubmittedOn" class="form-control input-sm"
                                            placeholder="SubmittedOn" disabled>
                                    </div>
                                </div>

                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                    <div class="my_profile_setting_input form-group">
                                        <label>Tentative Payment</label>
                                        <input type="text" [(ngModel)]="TentativePayment" class="form-control input-sm"
                                            placeholder="TentativePayment" disabled>
                                    </div>
                                </div>

                               
                           
                            </div>
                            <div class="row"> 

                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">

                                    <div class="row"> 
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                            <div class="my_profile_setting_input form-group">
                                                <label>Total Tariff<span class="star"> *</span></label>
                                                <input type="text" id="VendorTariff" [(ngModel)]="VendorTariff" maxlength="10"
                                                    class="form-control input-sm" placeholder="Total Tariff"
                                                    (input)="TotalInvoice()" appTwoDigitDecimaNumber>
                                              
                                            </div>
                                        </div>
        
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                            <div class="my_profile_setting_input form-group">
                                                <label>Tax Percentage </label><br/>
                                                <p-dropdown [options]="TTaxOpts" [(ngModel)]="selectedTTax"  (onChange)="onChangeTTax($event)" optionLabel="selectedTTax"></p-dropdown>
                                            </div>
                                        </div>
        
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                            <div class="my_profile_setting_input form-group">
                                                <label>Total Tariff CGST<span class="star"> *</span></label>
                                                <input type="text" id="CGSTAmount" [(ngModel)]="CGSTAmount"
                                                    class="form-control input-sm" placeholder="Total CGST" disabled>
                                                
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                            <div class="my_profile_setting_input form-group">
                                                <label>Total Tariff SGST<span class="star"> *</span></label>
                                                <input type="text" id="SGSTAmount" [(ngModel)]="SGSTAmount" maxlength="10"
                                                    class="form-control input-sm" placeholder="Total SGST" disabled>
                                               
                                            </div>
                                        </div>
                                    </div>   
                                    <div class="row"> 
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                            <div class="my_profile_setting_input form-group">
                                                <label>Total Food / Service</label>
                                                <input type="text" id="VendorServiceAmount" maxlength="10"
                                                    [(ngModel)]="VendorServiceAmount" class="form-control input-sm"
                                                    placeholder="Total Food / Service" (input)="TotalInvoice()"
                                                    appTwoDigitDecimaNumber>
        
                                            </div>
                                        </div>
        
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                            <div class="my_profile_setting_input form-group">
                                                <label>Tax Percentage </label><br/>
                                                <p-dropdown [options]="STaxOpts" [(ngModel)]="selectedSTax"  (onChange)="onChangeSTax($event)" optionLabel="selectedTTax"></p-dropdown>
                                            </div>
                                        </div>
        
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                            <div class="my_profile_setting_input form-group">
                                                <label>Total Food/Service CGST </label>
                                                <input type="text" id="VendorServiceCGST" maxlength="10"
                                                    [(ngModel)]="VendorServiceCGST" class="form-control input-sm"
                                                    placeholder="Total Service CGST" disabled>
        
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                            <div class="my_profile_setting_input form-group">
                                                <label>Total Food/Service SGST</label>
                                                <input type="text" id="VendorServiceSGST" [(ngModel)]="VendorServiceSGST"
                                                    class="form-control input-sm" placeholder="Total Service SGST" disabled>
                                               
                                            </div>
                                        </div>
                                    </div> 

                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12" data-title="Upload Invoice"
                                data-intro="Don't forget to Upload the Invoice." data-position="right" style="text-align: center;">
                           
                                    <label style="margin-top: 40px;">Invoice Upload ( jpg / png / pdf )<span class="star"> *</span></label><br>
                                
                                    <p-fileUpload #fubauto mode="basic" name="file" url="{{URL1}}" accept="application/pdf,image/*"
                                        (onUpload)="onBasicUploadAutoPdf($event)" (onSelect)="onBasicUploadAutoPdfCheck($event)" [auto]="true"
                                        chooseLabel="Browse"></p-fileUpload> <br />
                                    <label style="max-width:250px;">{{FileName}}</label>
                                 
                                </div>

 
                            </div>

                            <div class="row"> 
                               

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
                                    <div class="my_profile_setting_input form-group"
                                        style="text-align: center;font-weight: bold; margin-top: 5px;">
                                        <label>Total Invoice Value : <span style="color:#00B98E !important;font-weight: bold;text-align: center;">{{TotalInvoiceAmount | number : '1.2-2'}}</span></label> 
                                        
                                    </div>
                                </div>
                             



                            </div>

                       

                        </div>
                    </div>
                </section> -->


                <br />
                <section class="shop-checkouts spacegiven">
                    <div class="container">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" style="float: left;">
                                    <h4>Reservation Details</h4>
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                                    style="float: right;font-weight: bold;padding-left: 7%;padding-top: 1%;">
                                    <!-- <label>Total Value</label>
                                    <label style="color:#00B98E !important;margin-left: 10px;">{{TotalSelectedPOAmount |
                                        number : '1.2-2'}}</label> -->
                                </div>
                            </div>



                            <div class="row justify-content-md-center">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                                    <!-- <div class="row justify-content-md-center" >
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 dtrange">
                            <label>Date Range <span class="star"> *</span></label> 
                            <p-calendar [(ngModel)]="rangeDates"  showButtonBar="true"  [style]="{'width': '100%'}" selectionMode="range" [readonlyInput]="true" [numberOfMonths]="2" dateFormat="dd/mm/yy" [showIcon]="true" (onSelect)="onSelect()"></p-calendar>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                            <label>&nbsp;</label><br>
                            <button (click)="FnSearch()" pButton type="button" label="Search" class="ui-button-raised ui-button-info"></button>
                        </div>
                    </div> -->
                                    <!--  <div class="row justify-content-md-center">
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">

                                        </div>
                                      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>From Date <span class="star"> *</span></label><br/>
                                
                                <p-calendar [(ngModel)]="StartDate"  showButtonBar="true"  [readonlyInput]="true"  (onSelect)="StartDateSelected(StartDate)"  dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>To Date <span class="star"> *</span></label><br/>
                                <p-calendar [(ngModel)]="EndDate" [minDate]="StartDate" showButtonBar="true"  [readonlyInput]="true"  dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                           
                            <div class="my_profile_setting_input form-group">
                                <label>Search By</label><br/>
                                <p-dropdown [options]="Basedons" [(ngModel)]="selectedBasedon"  optionLabel="selectedBasedon"></p-dropdown>
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                            <label>&nbsp;</label><br>
                            <button (click)="FnSearch()" pButton type="button" label="Search" class="ui-button-raised ui-button-info"></button>
                        </div> -->
                                    <!-- <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                
                                <div  style="text-align: center;font-weight: bold;">
                                    <label>Total PO Value</label><br/>
                                <label style="color:#00B98E !important;;">{{TotalSelectedPOAmount | number : '1.2-2'}}</label>
                                </div>
                            </div>
                        </div> 
                                    </div> -->
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                            data-title="Reservation Details"
                                            data-intro="Select one or multiple reservations for above uploaded Invoice">

                                            <p-table #dt [columns]="cols" [value]="Orders" [resizableColumns]="true"
                                                [autoLayout]="true" [responsive]="true" [paginator]="true" [rows]="10"
                                                [showCurrentPageReport]="true"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                                                <ng-template pTemplate="caption">
                                                    <div style="text-align: right">
                                                        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>

                                                        <input type="text" pInputText size="25"
                                                            placeholder="Filter"
                                                            (input)="trimInput($event);dt.filterGlobal($event.target.value, 'contains')"
                                                            style="width:auto">
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="header" let-columns>
                                                    <tr>

                                                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                                            {{col.header}}
                                                            
                                                            <p-sortIcon  *ngIf="col.header!='Upload Invoice'" [field]="col.field"></p-sortIcon>
                                                        </th> 
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                                    <tr>

                                                        <td *ngFor="let col of columns">
                                                            <span class="ui-column-title">{{col.header}}</span>
                                                            <span *ngIf="col.header!='Upload Invoice'"
                                                                style="word-break: break-word;text-align: center;">{{rowData[col.field]}}</span>


                                                            <div *ngIf="col.header=='Upload Invoice'">
                                                                <p-fileUpload #fubauto{{rowData[col.field]}}  mode="basic" name="file" url="{{URL1}}/?data={{rowData[col.field]}}&source=grid"
                                                                accept="application/pdf,image/png,image/jpeg,image/jpg" (onUpload)="onBasicUploadAutoPdf($event,rowData,'grid')"
                                                                (onSelect)="onBasicUploadAutoPdfCheck($event,rowData)" [auto]="true"
                                                                chooseLabel="Upload"></p-fileUpload>
                                                           
                                                                <label style="font-size: 11px; display: block;">Only Pdf / Image files are allowed</label>
                                                            </div>


                                                        </td>
                                                       
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="summary">
                                                    <p style="text-align: center;" *ngIf="Orders.length==0">No records
                                                        found </p>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <!-- <section class="shop-checkouts spacegiven">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-4">

                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-4" data-title="Done"
                                data-intro="Submit your invoice, we will verify and update shortly ">

                                <button pButton type="button" class="ui-button-success" label="Continue"
                                    (click)="Continue()"></button>
                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-4">
                                <button pButton type="button" class="ui-button-info" label="Clear"
                                    (click)="MappingClear()"></button>
                            </div>
                        </div>
                    </div>
                </section> -->



            </div>
        </div>
    </main>

</div>
<br /><br /><br />

<!--  

<p-dialog header="Reservation Details" [(visible)]="displayModal" [style]="{width: '1350px'}" [modal]="true"
    [baseZIndex]="10" [draggable]="false" [resizable]="false" class="GDHF" [blockScroll]="true" (onHide)="Modalclose()" >
    
    <div>
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                Invoice No. Of Days: {{NoofDays}}
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                Total Invoice Amount: {{TotalInvoiceAmount | number : '1.2-2'}}
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                Total PO Amount: {{TotalSelectedPOAmount | number : '1.2-2'}}
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                Difference Amount: {{DifferenceAmount | number : '1.2-2'}}
              </div>
        </div>
    </div>
    
    <table id="customers">
        <thead>
            <tr>
                <th style="padding: 8px;border: 1px solid #ddd;">HB Booking Code</th>
                <th style="padding: 8px;border: 1px solid #ddd;">Guest Name</th>
                <th style="padding: 8px;border: 1px solid #ddd;">Stay Duration</th>
                <th style="padding: 8px;border: 1px solid #ddd;">Total Tariff</th>
                <th style="padding: 8px;border: 1px solid #ddd;">Tariff / Day</th>
                <th style="padding: 8px;border: 1px solid #ddd;">Total Food / Service</th>
                <th style="padding: 8px;border: 1px solid #ddd;">Balance Value</th>
                <th style="padding: 8px;border: 1px solid #ddd;">Short Stay</th>
                <th style="padding: 8px;border: 1px solid #ddd;">Stay Extend</th>
                <th style="width: 100px;border: 1px solid #ddd;">Actual Check-In Date</th>
                <th style="width: 100px;border: 1px solid #ddd;">Actual Check-Out Date</th>
                <th style="padding: 8px;padding: 8px;border: 1px solid #ddd;">Stay Days</th>
                <th style="padding: 8px;padding: 8px;border: 1px solid #ddd;">Tariff</th>
                <th style="padding: 8px;padding: 8px;border: 1px solid #ddd;">Service</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let car of SelectedPoDetails; index as i">
                <td style="border: 1px solid #ddd; padding: 8px;">{{car.BookingCode}}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">{{car.GuestName}}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">{{car.ChkOutStay}}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">{{car.POAmount}}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">{{car.POAmount_PerDay}}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">{{car.ServiceAmount}}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">{{car.BalanceAmount}}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">
                    <input type="checkbox" [(ngModel)]="car.ShortStay" (change)="ShortStay($event.target,car,i)" >
                </td>
                <td style="border: 1px solid #ddd; padding: 8px;">
                    <input type="checkbox" [(ngModel)]="car.StayExtend" (change)="StayExtend($event.target,car,i)">
                </td>
                <td style="border: 1px solid #ddd; padding: 8px;" class="GDFFH"><p-calendar dateFormat="dd/mm/yy"
                        [(ngModel)]="car.CheckInDte" [disabled]="car.Remaining_StayDays != car.StayDays"></p-calendar>
                </td>
                <td style="border: 1px solid #ddd; padding: 8px;" class="GDFFH"><p-calendar dateFormat="dd/mm/yy"
                        [(ngModel)]="car.CheckOutDte"></p-calendar></td>

                <td style="border: 1px solid #ddd; padding: 8px;" class="GDFFH1"><p-inputNumber
                        [(ngModel)]="car.NoofDays" (keyup)="NoofDaysChange(car,$event,i)"></p-inputNumber></td>

                <td style="border: 1px solid #ddd; padding: 8px;" class="GDFFH"><p-inputNumber
                        [(ngModel)]="car.ClacTariff" locale="en-IN" [minFractionDigits]="2" (keyup)="TariffChange($event,i)"></p-inputNumber></td>

                <td style="border: 1px solid #ddd; padding: 8px;" class="GDFFH"  (keyup)="NoofDaysChange(car,$event,i)"><p-inputNumber locale="en-IN"
                        [minFractionDigits]="2"></p-inputNumber></td>

            </tr>
        </tbody>
    </table>
    <p-footer style="margin: 0 auto;">
        <button type="button" pButton icon="pi pi-check" (click)="MappoFinalSave()" label="Process"></button>
        <button type="button" pButton icon="pi pi-times" (click)="Modalclose()" label="Close"
            class="ui-button-secondary"></button>
    </p-footer>
</p-dialog> -->

<p-dialog header="Booking & Upload Details" [(visible)]="displayModal" [style]="{width: '1350px'}" [modal]="true"
    [baseZIndex]="10" [draggable]="false" [resizable]="false" class="GDHF" [blockScroll]="true" (onHide)="Modalclose()">


    <p-accordion [multiple]="true">
        <p-accordionTab header="Reservation Details" [(selected)]="_ReservationDetailsflg">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
                    <p style="font-weight: bold; font-size: 14px; margin-top: 5px;">Reservation Details</p>
                    <hr style="margin-top: 5px;" /> 
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="my_profile_setting_input form-group">
                        <label>HB Booking Code : <b>{{_selectedPo.BookingCode}}</b> </label>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="my_profile_setting_input form-group">
                        <label>Guest Name : <b>{{_selectedPo.GuestName}}</b> </label>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="my_profile_setting_input form-group">
                        <label>Check-In Date : <b>{{_selectedPo.CheckInDte}}</b> </label>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="my_profile_setting_input form-group">
                        <label>Check-Out Date : <b>{{_selectedPo.CheckOutDte}}</b> </label>
                    </div>
                </div>


                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="my_profile_setting_input form-group">
                        <label>Tariff / Per Day : <b>{{_selectedPo.POAmount_PerDay}}</b> </label>
                    </div>
                </div>



                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="my_profile_setting_input form-group">
                        <label>Total Tariff : <b>{{_selectedPo.POAmount}}</b> </label>
                    </div>
                </div>


                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="my_profile_setting_input form-group">
                        <label> Food / Service : <b>{{_selectedPo.ServiceAmount}}</b> </label>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="my_profile_setting_input form-group">
                        <label> Balance Amount : <b>{{_selectedPo.BalanceAmount}}</b> </label>
                    </div>
                </div>


            </div>
        </p-accordionTab>
        <p-accordionTab header="Invoice Details" [(selected)]="_InvoiceDetailsflg" >

            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <p style="text-align: center; font-weight: bold; font-size: 16px; margin-top: 10px;">Invoice Details
                    </p>
                </div>
            </div>

            <div class="row">

                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <label>Submitted On &nbsp;&nbsp;&nbsp;&nbsp;: <b>{{SubmittedOn}}</b></label>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 clsRight">
                    <label>Tentative Payment : <b>{{TentativePayment}}</b></label>
                </div>

            </div>


            <p-accordion [multiple]="true" id="firstaccordion">
                <p-accordionTab header="Tariff Details" [(selected)]="_Tariffdetailsflg">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
                            <p style="font-weight: bold; font-size: 14px; margin-top: 5px;">Tariff Details</p>
                            <hr style="margin-top: 5px;" />
                        </div>
                    </div>


                    <div class="row">

                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-title="Upload Invoice"
                            data-intro="Don't forget to Upload the Invoice." data-position="right"
                            style="text-align: center;">

                            <label style="margin-top: 30px;">Invoice Upload ( jpg / png / pdf )<span class="star"> *</span></label><br>

                            <p-fileUpload #fubautotariff mode="basic" name="file" url="{{URL1}}/?data={{_selectedPo.Chk}}&source=tariff"
                            accept="application/pdf,image/png,image/jpeg,image/jpg" (onUpload)="onBasicUploadAutoPdf($event,1,'tariff')"
                                (onSelect)="onBasicUploadAutoPdfCheck($event,1)" [auto]="true"
                                chooseLabel="Browse"></p-fileUpload> 
                                &nbsp;
                                 
                                
                                <br />

                            <label style="max-width:250px; cursor: pointer;text-decoration: underline; color: blue; word-wrap: break-word; " (click)="Openfile(_tariffFileUrl)">{{TraiffFileName}}</label> 

                        </div>

                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div class="my_profile_setting_input form-group">
                                        <label>Invoice No <span class="star"> *</span></label>
                                        <input type="text" placeholder="Invoice No" class="form-control"
                                            name="InvoiceNo" [(ngModel)]="InvoiceNo" (input)="trimInput($event)">
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div class="my_profile_setting_input form-group CalHeight">
                                        <label>Invoice Date <span class="star"> *</span></label><br />
                                        <p-calendar [style]="{'width':'100%'}" [maxDate]="InvoiceMaxDate"
                                            [(ngModel)]="InvoiceDate" placeholder="Invoice Date" showButtonBar="true"
                                            [readonlyInput]="true" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div class="my_profile_setting_input form-group">
                                        <label>No.of Stay Days<span class="star"> *</span></label>
                                        <input type="text" class="form-control" placeholder="No.of Stay Days" 
                                            maxlength="2" pKeyFilter="int" name="NoofDays" [(ngModel)]="NoofDays" appNoMinus>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div class="my_profile_setting_input form-group">
                                        <label>Total Tariff<span class="star"> *</span></label>
                                        <input type="text" id="VendorTariff" [(ngModel)]="VendorTariff" maxlength="10"
                                            class="form-control input-sm" placeholder="Total Tariff"
                                              appTwoDigitDecimaNumber (input)="TotalInvoice()">

                                    </div>
                                </div>


                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div class="my_profile_setting_input form-group">
                                        <label>Total Tariff CGST<span class="star"> *</span></label>
                                        <input type="text" id="CGSTAmount" [(ngModel)]="CGSTAmount"
                                            class="form-control input-sm" placeholder="Total CGST" 
                                            appTwoDigitDecimaNumber (input)="TotalInvoice()" maxlength="10">

                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div class="my_profile_setting_input form-group">
                                        <label>Total Tariff SGST<span class="star"> *</span></label>
                                        <input type="text" id="SGSTAmount" [(ngModel)]="SGSTAmount" maxlength="10"
                                            class="form-control input-sm" placeholder="Total SGST" 
                                            appTwoDigitDecimaNumber (input)="TotalInvoice()">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: right;">
                            <p><b>Total Tariff Amount : {{TotalTariffInvoiceAmount | number : '1.2-2'}}</b></p>
                        </div>
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Service Details" [(selected)]="_ServiceDetailsflg">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
                        <p style="font-weight: bold; font-size: 14px; margin-top: 5px;">Service Details</p>
                        <hr style="margin-top: 5px;" />
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-title="Upload Invoice"
                            data-intro="Don't forget to Upload the Invoice." data-position="right"
                            style="text-align: center;">

                            <label>Invoice Upload ( jpg / png / pdf ) <span *ngIf="ServiceInvoiceNo !=''" class="star"> *</span></label><br>

                            <p-fileUpload #fubautoservice mode="basic" name="file" url="{{URL1}}/?data={{_selectedPo.Chk}}&source=service"
                            accept="application/pdf,image/png,image/jpeg,image/jpg" (onUpload)="onBasicUploadAutoPdf($event,1,'service')"
                                (onSelect)="onBasicUploadAutoPdfCheck($event,1)" [auto]="true"
                                chooseLabel="Browse"></p-fileUpload> <br />
                            <label style="max-width:250px; cursor: pointer;text-decoration: underline; color: blue; word-wrap: break-word;" (click)="Openfile(_serviceFileUrl)">{{ServiceFileName}}</label>
                            <p>If service has a separate invoice. Please upload the respective invoices.</p>

                        </div>

                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div class="my_profile_setting_input form-group">
                                        <label>Invoice No <span *ngIf="ServiceFileName !=''" class="star"> *</span></label>
                                        <input type="text" placeholder="Invoice No" class="form-control"
                                            name="InvoiceNo" [(ngModel)]="ServiceInvoiceNo" (input)="trimInput($event)">
                                    </div>
                                </div>

                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div class="my_profile_setting_input form-group">
                                        <label>Total Food / Service <span *ngIf="_selectedPo.ServiceAmount > 0 " class="star"> *</span></label>
                                        <input type="text" id="VendorServiceAmount" maxlength="10"
                                            [(ngModel)]="VendorServiceAmount" class="form-control input-sm"
                                            placeholder="Total Food / Service"  
                                            appTwoDigitDecimaNumber (input)="TotalInvoice()">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div class="my_profile_setting_input form-group">
                                        <label>Total Food / Service CGST <span *ngIf="_selectedPo.ServiceAmount > 0" class="star"> *</span></label>
                                        <input type="text" id="VendorServiceCGST" maxlength="10"
                                            [(ngModel)]="VendorServiceCGST" class="form-control input-sm"
                                            placeholder="Total Service CGST" 
                                            appTwoDigitDecimaNumber (input)="TotalInvoice()">
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div class="my_profile_setting_input form-group">
                                        <label>Total Food / Service SGST <span *ngIf="_selectedPo.ServiceAmount > 0" class="star"> *</span></label>
                                        <input type="text" id="VendorServiceSGST" [(ngModel)]="VendorServiceSGST"
                                            class="form-control input-sm" placeholder="Total Service SGST" 
                                            appTwoDigitDecimaNumber (input)="TotalInvoice()" maxlength="10">
                                    </div>
                                </div>


                            </div> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: right;">
                            <p><b>Total Service Amount : {{TotalServiceInvoiceAmount | number : '1.2-2'}}</b></p>
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>






        </p-accordionTab>
    </p-accordion>





    <hr />

    <div class="row">
        <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12">

        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12" style="text-align: right;">
            <b> Total Invoice Amount : {{TotalInvoiceAmount | number : '1.2-2'}}</b>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12" data-title="Done"
            data-intro="Submit your invoice, we will verify and update shortly ">

            <button pButton type="button" class="ui-button-success" label="Process" (click)="Continue()" style="margin-bottom: 10px;"></button>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12">
            <button pButton type="button" class="ui-button-info" label="Clear" (click)="MappingClear()" style="margin-bottom: 10px;"></button>
        </div>
    </div>



</p-dialog>


<p-dialog header="Confirmation" [(visible)]="_displayModal1" [style]="{width: '350px'}" [modal]="true"
    [baseZIndex]="10" [draggable]="false" [resizable]="false"  >
    
    <div>
        <br/> 
        <p style="font-size: 15px;">The number of days is less than Po days. Do you want to mark it as a short stay?</p>
        <br/> 
    </div>
     
    <p-footer style="margin: 0 auto;">
        <button type="button" pButton icon="pi pi-check" (click)="ProcessInvoice('Yes')" label="Yes"></button>
        <button type="button" pButton icon="pi pi-times" (click)="ProcessInvoice('No')" label="No"
            class="ui-button-secondary"></button>
    </p-footer>
</p-dialog>

<p-dialog header="Confirmation" [(visible)]="_displayModal2" [style]="{width: '350px'}" [modal]="true"
    [baseZIndex]="10" [draggable]="false" [resizable]="false"  >
    
    <div>
        <br/> 
        <p style="font-size: 15px;">Do you want to process?</p>
        <br/> 
    </div>
     
    <p-footer style="margin: 0 auto;">
        <button type="button" pButton icon="pi pi-check" (click)="ProcessInvoiceNew('Yes')" label="Yes"></button>
        <button type="button" pButton icon="pi pi-times" (click)="ProcessInvoice('No Action')" label="No"
            class="ui-button-secondary"></button>
    </p-footer>
</p-dialog>