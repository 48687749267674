import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router,NavigationEnd   } from '@angular/router';
import { ToastyService, ToastyConfig, ToastOptions, ToastData } from 'ng2-toasty'; 
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Httpclient } from '../_services/authorization.service';
var FileSaver = require('file-saver');
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {

  UserId: string;
  Orders: any;
  cols: any;
  RowId:string;
  results: any = [] = [];
  currentRoute: any = [];
  Globalfiltertext:string ='';

  constructor(private http: Httpclient,
    private route: ActivatedRoute,
    private router: Router, 
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService) { 
     
    }

  ngOnInit(): void {
   
    this.RowId = localStorage.getItem("_PropertyuniqueId"); 
           
    if(this.RowId == "null" || this.RowId ==null )
    {
      window.location.href = environment.BaseUrl + "#/error404";

    }
    this.Orders =[];
    this.cols = [
      { field: 'BookingCode', header: 'HB Booking Code' }, 
      { field: 'PropertyRef', header: 'Hotel Ref No' },  
      { field: 'GuestName', header: 'Guest Name' }, 
      { field: 'RoomType', header: 'Room Type' },  
      { field: 'ToBePaidOn', header: 'Payment Scheduled Date' },   
      { field: 'CheckInDate', header: 'Check-In Date' },
      { field: 'CheckOutDate', header: 'Check-out Date' },
      { field: 'TotalPayable', header: 'Total Payable' },
   
  ];
  
  
      this.pageload();
  }

  pageload() {
    var bkDtls = {  
      PropertyRowId:this.RowId, 
      UserId: 1
    }
    
    return this.http.post("getpaymentdetails", bkDtls).toPromise().then(res => {
      debugger;
      const parseJson =JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);  
      this.Orders = parsedJson.paymentdetails;
      this.spinner.hide();
    }).catch(err => {      
      this.spinner.hide(); 
      return err;
    });
  }

  trimInput(event: any) {
    const value = event.target.value;
    if (/^\s/g.test(value)) {
      var value1 = value?.replace(/^\s/g, "");
      event.target.value = value1.trim();
    } 
  }
}