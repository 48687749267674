<ng2-toasty></ng2-toasty>
<div class="jumbotron">


    <main class="content-wrapper" id="closed">
        <div class="container-fluid">
            <div class="main_content_container">
                <br />   <br />                  
                <section class="shop-checkouts spacegiven">
                    <div class="container">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" style="float: left;">
                                    <h4>Reservation Details</h4>
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                                    style="float: right;font-weight: bold;padding-left: 7%;padding-top: 1%;">
                               
                                </div>
                            </div>


                            <hr />
                            <div class="row justify-content-md-center">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                                    <!-- <div class="row justify-content-md-center" >
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 dtrange">
                            <label>Date Range <span class="star"> *</span></label> 
                            <p-calendar [(ngModel)]="rangeDates"  showButtonBar="true"  [style]="{'width': '100%'}" selectionMode="range" [readonlyInput]="true" [numberOfMonths]="2" dateFormat="dd/mm/yy" [showIcon]="true" (onSelect)="onSelect()"></p-calendar>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                            <label>&nbsp;</label><br>
                            <button (click)="FnSearch()" pButton type="button" label="Search" class="ui-button-raised ui-button-info"></button>
                        </div>
                    </div> -->
                                    <div class="row justify-content-md-center">
                                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">

                                        </div>
                                        <!-- <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>From Date <span class="star"> *</span></label><br/>
                                
                                <p-calendar [(ngModel)]="StartDate"  showButtonBar="true"  [readonlyInput]="true"  (onSelect)="StartDateSelected(StartDate)"  dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                <label>To Date <span class="star"> *</span></label><br/>
                                <p-calendar [(ngModel)]="EndDate" [minDate]="StartDate" showButtonBar="true"  [readonlyInput]="true"  dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                           
                            <div class="my_profile_setting_input form-group">
                                <label>Search By</label><br/>
                                <p-dropdown [options]="Basedons" [(ngModel)]="selectedBasedon"  optionLabel="selectedBasedon"></p-dropdown>
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                            <label>&nbsp;</label><br>
                            <button (click)="FnSearch()" pButton type="button" label="Search" class="ui-button-raised ui-button-info"></button>
                        </div> -->
                                        <!-- <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                            <div class="my_profile_setting_input form-group">
                                
                                <div  style="text-align: center;font-weight: bold;">
                                    <label>Total PO Value</label><br/>
                                <label style="color:#00B98E !important;;">{{TotalSelectedPOAmount | number : '1.2-2'}}</label>
                                </div>
                            </div>
                        </div> -->
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                            data-title="Reservation Details"
                                            data-intro="Select one or multiple reservations for above uploaded Invoice">
                                            <br>
                                            <p-table #dt [columns]="cols" [value]="Orders" [resizableColumns]="true"
                                                [autoLayout]="true" [responsive]="true" [paginator]="true" [rows]="10"
                                                [showCurrentPageReport]="true"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                                                <ng-template pTemplate="caption">
                                                    <div style="text-align: right">
                                                        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                                        <input type="text" pInputText size="25"
                                                            placeholder="Filter"
                                                            (input)="trimInput($event);dt.filterGlobal($event.target.value, 'contains')"
                                                            style="width:auto" [(ngModel)]="Globalfiltertext">
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="header" let-columns>
                                                    <tr>
                                                       
                                                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                                            {{col.header}}
                                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                                    <tr>
                                                       
                                                        <td *ngFor="let col of columns">
                                                            <span class="ui-column-title">{{col.header}}</span>
                                                            <span *ngIf="col.header!='Action'"
                                                                style="word-break: break-word;text-align: center;">{{rowData[col.field]}}</span>

                                                            <button *ngIf="col.header=='Action'"
                                                                (click)="MappingSave(rowData[col.field])" type="button" pButton  
                                                                title="Mark it as NoShow" label="No Show" style="width: 100px;"></button>


                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="summary">
                                                    <p style="text-align: center;" *ngIf="Orders.length==0">No records
                                                        found </p>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

 



            </div>
        </div>
    </main>

</div> 


<p-dialog header="Confirmation" [(visible)]="_displayModal1" [style]="{width: '350px'}" [modal]="true"
    [baseZIndex]="10" [draggable]="false" [resizable]="false"  >
    
    <div>
        <br/> 
        <p style="font-size: 15px;">Do you want to mark it as No Show?</p>
        <br/> 
    </div>
     
    <p-footer style="margin: 0 auto;">
        <button type="button" pButton icon="pi pi-check" (click)="ProcessInvoice('Yes')" label="Yes"></button>
        <button type="button" pButton icon="pi pi-times" (click)="ProcessInvoice('No')" label="No"
            class="ui-button-secondary"></button>
    </p-footer>
</p-dialog>