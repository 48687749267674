import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router,NavigationEnd   } from '@angular/router';
import { ToastyService, ToastyConfig, ToastOptions, ToastData } from 'ng2-toasty'; 
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Httpclient } from '../_services/authorization.service';
import {environment} from '../../environments/environment';



@Component({
  selector: 'app-noshow',
  templateUrl: './noshow.component.html',
  styleUrls: ['./noshow.component.css']
})
export class NoshowComponent implements OnInit {

  @ViewChild('dt') dt: any;
  
  RowId:string;
  Orders: any;
  cols: any;
  UserId: string;
  SelectedPoDetails:any[] = [];
  _NoShowId: string;
  _displayModal1: boolean = false;
  Globalfiltertext: string = '';
  _HotelId: string = "";
  HotelName: string = "";

  constructor(private http: Httpclient,
    private route: ActivatedRoute,
    private router: Router, 
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService) { }

  ngOnInit(): void {
    
    this.RowId = localStorage.getItem("_PropertyuniqueId"); 

    if(this.RowId == "null" || this.RowId ==null )
    {
      window.location.href = environment.BaseUrl + "#/error404";

    }

    this.spinner.show()
    this.Orders =[];

    this.cols = [
      { field: 'BookingCode', header: 'HB Booking Code' }, 
      { field: 'PropertyRef', header: 'Hotel Ref No' },
      { field: 'POAmount_PerDay', header: 'Tariff / Day' }, 
      { field: 'StayDays', header: 'Stay Days' }, 
      { field: 'POAmount', header: 'Total Tariff' }, 
      { field: 'ServiceAmount', header: 'Total Food / Service' },       
      { field: 'BalanceAmount', header: 'Balance Value' },       
      { field: 'GuestName', header: 'Guest Name' },  
      { field: 'ChkOutStay', header: 'Stay Duration' },
      { field: 'TableId', header: 'Action' },
   
  ];

  var mapDtls = { 
    FromDt: "",
    ToDt: "",
    RowId:this.RowId,
    Basedon: "",
    UserId: this.UserId
  }
  this.Globalfiltertext = '';
  setTimeout(()=>{   
  this.http.post("getbookingnoshowdetails", mapDtls).toPromise().then(res => {
    const parseJson =JSON.stringify(res);
    const parsedJson = JSON.parse(parseJson);  
    this.Orders = parsedJson.PoDetails[0]; 
    this.HotelName = parsedJson.PoDetails[1][0].PropertyName;
    this._HotelId = parsedJson.PoDetails[1][0].Id;
    this.spinner.hide();
  }).catch(err => {      
    this.spinner.hide(); 
    return err;
  });
}, 200);


  }

  MappingSave(id) {
    this._displayModal1 = true;
    this._NoShowId = id;    
  }
   
  ProcessInvoice(res) {
    if(res=="Yes") {
      debugger;
      var toastOptions: ToastOptions = {
        title: "Alert",
        showClose: true,
        timeout: 10000,
        theme: 'material',
        onAdd: (toast: ToastData) => {
        },
        onRemove: function (toast: ToastData) {
        }
      };
      toastOptions.msg = "";
  
      for (var i = 0; i < this.Orders.length; i++) {
        if (this.Orders[i].TableId == this._NoShowId) {
          this.SelectedPoDetails.push(this.Orders[i]);
        }
      }
  
      var SelectedDtls = {
        PropertyRowId: this.RowId,
        SelectedPoDtls: this.SelectedPoDetails[0],
        PropertyId: this._HotelId,
        PropertyName: this.HotelName,
  
      }
      this.spinner.show();
      
      return this.http.post("hotelNoShowmappingsave", SelectedDtls).toPromise().then(res => {
        const parseJson = JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        if (parsedJson.message == "Invoice Mapped Successfully") {
          this._displayModal1 = false;
          toastOptions.msg = "The booking has been marked as a No Show";
          this.toastyService.success(toastOptions);
          this.Orders = [];
          var mapDtls = {
            FromDt: "",
            ToDt: "",
            RowId: this.RowId,
            Basedon: "",
            UserId: this.UserId
          }
          this.Globalfiltertext = '';
          this.dt.reset();
          setTimeout(() => {
            this.http.post("getbookingnoshowdetails", mapDtls).toPromise().then(res => {
              const parseJson = JSON.stringify(res);
              const parsedJson = JSON.parse(parseJson);
              this.Orders = parsedJson.PoDetails[0];
              this.HotelName = parsedJson.PoDetails[1][0].PropertyName;
              this._HotelId = parsedJson.PoDetails[1][0].Id;
              this.spinner.hide();
            }).catch(err => {
              this.spinner.hide();
              return err;
            });
          }, 100);
        }
        this.spinner.hide();
      }).catch(err => {
        this.spinner.hide();
        this._displayModal1 = false;
        toastOptions.msg = "An error occurred while saving the record.";
        this.toastyService.error(toastOptions);
        return err;
      });
    }
    else {
      this._displayModal1 = false;
    }
 
  
  
  }
 

  MappingClear() {
    this.spinner.show();
    this.Orders = [];
    var mapDtls = {
      FromDt: "",
      ToDt: "",
      RowId: this.RowId,
      Basedon: "",
      UserId: this.UserId
    }
    this.Globalfiltertext = '';
    setTimeout(() => {
      this.http.post("getbookingnoshowdetails", mapDtls).toPromise().then(res => {
        const parseJson = JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        this.Orders = parsedJson.PoDetails[0];
        this.HotelName = parsedJson.PoDetails[1][0].PropertyName;
        this._HotelId = parsedJson.PoDetails[1][0].Id;
        this.spinner.hide();
      }).catch(err => {
        this.spinner.hide();
        return err;
      });
    }, 100);
  }

  trimInput(event: any) {
    const value = event.target.value;
    if (/^\s/g.test(value)) {
      var value1 = value?.replace(/^\s/g, "");
      event.target.value = value1.trim();
    } 
  }
}
