import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router,NavigationEnd   } from '@angular/router';
import { ToastyService, ToastyConfig, ToastOptions, ToastData } from 'ng2-toasty'; 
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Httpclient } from '../_services/authorization.service';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements AfterViewInit {



  constructor(private http: Httpclient,
    private route: ActivatedRoute,
    private router: Router, 
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService) { 
      // this.introJS.setOptions({
      //   steps: [
      //   {
      //      element: '#step1',
      //      intro: 'Year of 2021-22 Total Reservations,Billing & Invoices!',
      //      position: 'top'
      //   },
      //   {
      //      element: '#step2',
      //      intro: "Month of Feb Total Reservations,Billing & Invoices!",
      //      position: 'right'
      //   },],
      //   showProgress: true
      //  });
    }

  data: any;
  RowId:string;
  PropertyName:string;
  CurrentMonth:string;
  CurrentYear:string;
  YearReservations:string;
  YearBilling:string;
  MonthBilling:string;
  YearInvoices:string;
  YearPendingInvoices:string;
  MonthReservations:string;
  TodayCheckin:string;
  TodayCheckout:string;

  YearInvoiceMapped:string;
  YearInvoiceUnMapped:string;
  MonthInvoiceMapped:string;
  MonthInvoiceUnMapped:string;
  ngOnInit() {
    
    this.RowId = localStorage.getItem("_PropertyuniqueId"); 
    
    

    

   
  }

  menuclick(event)
  {
    debugger;
    if(event=="dashboard")
    {
      $('#dashboards').addClass("active");
      $('#Invoice').removeClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').removeClass("active");
      
    }
    else if(event =="billupload")
    {
      $('#dashboards').removeClass("active");
      $('#Invoice').addClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').removeClass("active");
    }
    else if(event == "bookings")
    {
      $('#dashboards').removeClass("active");
      $('#Invoice').removeClass("active");
      $('#Bookings').addClass("active");
      $('#TotalInvoice').removeClass("active");
    }
    else if(event == "invoices")
    {
      $('#dashboards').removeClass("active");
      $('#Invoice').removeClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').addClass("active");
    }

  }

  ngAfterViewInit() {

    var Dtls = { 
      PropertyRowId:this.RowId,
      UserId: 1
    }
    return this.http.post("dashboarddetails", Dtls).toPromise().then(res => {
      debugger;
      const parseJson =JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);  
      this.data = parsedJson.DashboardDtls;
      this.PropertyName = this.data[0][0].PropertyName;
      this.CurrentMonth = this.data[1][0].CurrentMonth;
      this.CurrentYear = this.data[1][0].CurrentYear;
      this.YearReservations = this.data[2][0].RoomNight_Count_FY;
      this.MonthReservations = this.data[3][0].RoomNight_Count_CurrentMonth;
      var YearBill = (parseFloat (this.data[4][0].GTV_Apr21_Feb22)/100000);
      var MonthBill = (parseFloat (this.data[5][0].GTV_Feb22)/100000);
      this.TodayCheckin = this.data[6][0].TodayCheckIn;
      this.TodayCheckout = this.data[7][0].TodayCheckOut;
      this.YearInvoiceMapped = this.data[8][0].Invoice_Mapped_Count_FY;
      this.YearInvoiceUnMapped = this.data[9][0]. Invoice_UnMapped_Count_FY;
      this.MonthInvoiceMapped = this.data[10][0].Invoice_Mapped_Count_CurrentMonth;
      this.MonthInvoiceUnMapped = this.data[11][0]. Invoice_UnMapped_Count_CurrentMonth;
      this.YearBilling = YearBill.toString();
      this.MonthBilling = MonthBill.toString();
      
      this.spinner.hide();
      setTimeout(()=>{                           //<<<---using ()=> syntax
        $('.timer').each(function () {
          $(this).prop('Counter',0).animate({
              Counter: $(this).text()
          }, {
              duration: 2000,
              easing: 'swing',
              step: function (now) {
                  $(this).text(parseFloat(now).toFixed(0));
              }
          });
      });
   }, 100);
   setTimeout(()=>{                           //<<<---using ()=> syntax
    $('.timer1').each(function () {
      $(this).prop('Counter',0).animate({
          Counter: $(this).text()
      }, {
          duration: 2000,
          easing: 'swing',
          step: function (now) {
              $(this).text(parseFloat(now).toFixed(2));
          }
      });
  });
}, 100);
     
    }).catch(err => {      
      this.spinner.hide(); 
      return err;
    });

    
    
  }
  
}



