import { Directive,ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoMinus]'
})
export class NoMinusDirective {

  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);

  constructor(private el: ElementRef) {
  }
  
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === '-') {
      event.preventDefault();
    }
  }

  
  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const value = this.el.nativeElement.value;
    if (!String(value).match(this.regex)) {
      this.el.nativeElement.value = value.slice(0, -1);
    }
  }

}