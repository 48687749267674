import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ToastyService, ToastyConfig, ToastOptions, ToastData } from 'ng2-toasty';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Httpclient } from '../_services/authorization.service';
var FileSaver = require('file-saver');
import { environment } from '../../environments/environment';

declare var $: any;
interface Basedon {
  name: string,
  selectedBasedon: string
}
@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  rangeDates: Date[];
  assigndate: Date[];
  StartDate: Date;
  EndDate: Date;
  Basedons: Basedon[];
  selectedBasedon: Basedon;
  UserId: string;
  Orders: any;
  cols: any;
  RowId: string;
  currentRoute: any = [];
  results: any = [] = [];
  Globalfiltertext: string;
  constructor(private http: Httpclient,
    private route: ActivatedRoute,
    private router: Router,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService) {

  }

  ngOnInit(): void {
    debugger;
    this.RowId = localStorage.getItem("_PropertyuniqueId");

    if (this.RowId == "null" || this.RowId == null) {
      window.location.href = environment.BaseUrl + "#/error404";

    }
    this.Orders = [];
    this.cols = [
      { field: 'BookingCode', header: 'HB Booking Code' },
      { field: 'PropertyRef', header: 'Hotel Ref No' },
      // { field: 'POAmount_PerDay', header: 'Tariff / PerDay' }, 
      // { field: 'StayDays', header: 'Stay Days' }, 

      { field: 'POAmount', header: 'Total Tariff' },
      { field: 'GuestName', header: 'Guest Name' },
      { field: 'ChkOutStay', header: 'Stay Duration' },
      { field: 'InvoiceStatus', header: 'Invoice Status' },
      { field: 'Advanceamount', header: 'Paid Amount' },
      { field: 'ReferenceNumber', header: 'Payment Ref No' },
      { field: 'DateofPayment', header: 'Payment Date' },


    ];
    this.Basedons = [
      { name: 'Check-In Date', selectedBasedon: 'Check-In Date' } 


    ];
    this.selectedBasedon =
      { name: 'Check-In Date', selectedBasedon: 'Check-In Date' }

    // this.StartDate = new Date();
    // let datePlus = new Date().getDate();
    // let datePlus1 = new Date().getDate() -1;
    // this.EndDate = new Date();
    // this.EndDate.setDate(datePlus);
    // this.StartDate.setDate(datePlus1); 
    this.pageload();
  }

  pageload() {
    var bkDtls = {
      StartDate: '',
      EndDate: '',
      PropertyRowId: this.RowId,
      Basedon: this.selectedBasedon.selectedBasedon,
      UserId: 1
    }

    return this.http.post("getinvoicehistorydetail", bkDtls).toPromise().then(res => {
      debugger;
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
      this.Orders = parsedJson.Invoicehistory;
      this.spinner.hide();
    }).catch(err => {
      this.spinner.hide();
      return err;
    });
  }



  FnExcel() {
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 3000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";

    if (this.StartDate == undefined) {
      toastOptions.msg += 'From Date is required' + '</br>';
    }
    else {
      if (this.EndDate == null || this.EndDate == undefined) {
        toastOptions.msg += 'To Date is required' + '</br>';
      }
    }

    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
    }
    else {

      var dd = this.StartDate.getDate();
      var mm = this.StartDate.getMonth() + 1;
      var yy = this.StartDate.getFullYear();
      var StartDate = dd + "/" + mm + "/" + yy;
      var dd = this.EndDate.getDate();
      var mm = this.EndDate.getMonth() + 1;
      var yy = this.EndDate.getFullYear();
      var EndDate = dd + "/" + mm + "/" + yy;

      var bkDtls = {
        StartDate: StartDate,
        EndDate: EndDate,
        PropertyRowId: this.RowId,
        Basedon: this.selectedBasedon.selectedBasedon,
        UserId: 1
      }

      return this.http.post("InvoicegenerateExcel", bkDtls).toPromise().then(data => {
        this.results = data;
        var res = this.results.Result;
        var resFileName = this.results.FileName;
        FileSaver.saveAs(res, resFileName);
        this.spinner.hide();
      }).catch(err => {
        var toastOptions1: ToastOptions = {
          title: "Alert",
          showClose: true,
          timeout: 3000,
          theme: 'material',
          onAdd: (toast: ToastData) => {
          },
          onRemove: function (toast: ToastData) {
          }
        };
        this.spinner.hide();
        toastOptions1.msg = "No records found";
        this.toastyService.error(toastOptions1);

      });
    }
  }

  onChangeSearch(event) {
    this.Orders = [];
    this.Globalfiltertext = '';
  }
  FnSearch() {
    this.Globalfiltertext = '';
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 3000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";

    if (this.StartDate == undefined) {
      toastOptions.msg += 'From Date is required' + '</br>';
    }
    else {
      if (this.EndDate == null) {
        toastOptions.msg += 'To Date is required' + '</br>';
      }
    }

    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
    }
    else {

      var dd = this.StartDate.getDate();
      var mm = this.StartDate.getMonth() + 1;
      var yy = this.StartDate.getFullYear();
      var StartDate = dd + "/" + mm + "/" + yy;
      var dd = this.EndDate.getDate();
      var mm = this.EndDate.getMonth() + 1;
      var yy = this.EndDate.getFullYear();
      var EndDate = dd + "/" + mm + "/" + yy;

      var bkDtls = {
        StartDate: StartDate,
        EndDate: EndDate,
        PropertyRowId: this.RowId,
        Basedon: this.selectedBasedon.selectedBasedon,
        UserId: 1
      }

      return this.http.post("getinvoicehistorydetail", bkDtls).toPromise().then(res => {
        debugger;
        const parseJson = JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        this.Orders = parsedJson.Invoicehistory;
        this.spinner.hide();
      }).catch(err => {
        this.spinner.hide();
        return err;
      });
    }
  }

  onSelect() {
    debugger;
    if (this.rangeDates[1] !== null) {

      $("#closed").trigger("click");
    }

  }
  StartDateSelected(StartDate) {
    let datePlus = new Date(StartDate).getDate() + 30;

    this.EndDate = new Date(StartDate);
    this.EndDate.setDate(datePlus)

    this.Orders = [];
    this.Globalfiltertext = '';
    //  let TT = new Date(this.StartDate.getDate()+1);
    //  let TT1 = new Date(this.StartDate.getMonth());
    //  let TT2 = new Date(this.StartDate.getFullYear());
    //   //  this.EndDate = new Date(StartDate +1);
    //    this.EndDate.setDate(this.StartDate.getDay()+1)
  }

  trimInput(event: any) {
    const value = event.target.value;
    if (/^\s/g.test(value)) {
      var value1 = value?.replace(/^\s/g, "");
      event.target.value = value1.trim();
    } 
  }

  StartDateSelectedToDte(EndDate)
  {
    this.Orders = [];
    this.Globalfiltertext = '';
  }
}
