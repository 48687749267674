import { Component, OnInit, ViewChild } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router,NavigationEnd   } from '@angular/router';
import { ToastyService, ToastyConfig, ToastOptions, ToastData } from 'ng2-toasty'; 
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Httpclient } from '../_services/authorization.service';
import { element } from 'protractor';
import { ConvertActionBindingResult } from '@angular/compiler/src/compiler_util/expression_converter';
import { parse } from 'querystring';
import { date } from 'azure-storage';
import * as introJs from 'intro.js/intro.js'; 
import * as e from 'cors';
import {environment} from '../../environments/environment';


interface Basedon {
  name: string,
  selectedBasedon: string
}

interface TTaxOpt {
  name: string,
  selectedTTax: string
}

declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-mapping-invoice',
  templateUrl: './mapping-invoice.component.html',
  styleUrls: ['./mapping-invoice.component.css']
})
export class MappingInvoiceComponent implements OnInit {
 

  rangeDates: Date[];
  assigndate: Date[];
  logindetails: any; 
  Basedons: Basedon[];
  selectedBasedon: Basedon;
  NoticePeriod: any;
  StartDate: Date;
  EndDate:Date;
  UserId: string;
  Orders: any;
  OrdersTmp: any;
  cols: any;
  RowId:string;
  currentRoute: any = [];
  InvoiceNo:string;
  InvoiceDate:Date;
  NoofDays:string;
  FileUrl:string;
  TotalSelectedPOAmount :string;
  Globalfiltertext:string;
  URL1:string = environment.BaseAPIUrl+ "/imageupload1"; 
  FileName:string;
  uploadedFiles: any[] = [];
  InvoiceLocation:string;
  VendorTariff:string;
  VendorServiceAmount:string;
  CGSTAmount:string;
  SGSTAmount:string;
  VendorServiceCGST:string;
  VendorServiceSGST:string;
  TempVendorTariff:Number;
  TempVendorServiceAmount:Number;
  TempCGSTAmount:Number;
  TempSGSTAmount:Number;
  TempServiceCGSTAmount:Number;
  TempServiceSGSTAmount:Number;
  TotalInvoiceAmount:string;
  TotalTariffInvoiceAmount: string;
  SelectedPoDetails:any[] = [];
  HotelGstNumber:string;
  HotelName:string;
  HotelAddress:string;
  HBGstNumber:string;
  HBName:string;
  HBAddress:string;
  selectedValues: boolean=true;
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  
  SubmittedOn: string="";
  TentativePayment: string="";
  TTaxOpts: TTaxOpt[];
  selectedTTax: TTaxOpt;
  
  STaxOpts: TTaxOpt[];
  selectedSTax: TTaxOpt;
  displayModal: boolean;
  DifferenceAmount: string;
  BookingCode: string;

  _selectedPo: any = [];
  _tariffFileUrl:string;
  _serviceFileUrl:string;
  TraiffFileName:string = "";
  ServiceFileName:string = "";
  _InvoiceDetails: any = [];
  TotalServiceInvoiceAmount: string="0";
  index: number = 0;
  _ReservationDetailsflg: boolean = false;
  _InvoiceDetailsflg: boolean = false;
  _Tariffdetailsflg: boolean = false;
  _ServiceDetailsflg: boolean = false;
  ServiceInvoiceNo: string = "";
  _ExtractionStatus: string = "";
  _MapPOclose_flag: boolean = false;
  _HotelId: string = "";
  _ServiceAmountFlg: boolean = false;
  _displayModal1: boolean = false;
  _displayModal2: boolean = false;


  _WriteOffAmount: string = "0";
  _WriteOffFlg: boolean = false;
  _ShortStayAmount: string = "0";
  _ShortStayFlg: boolean  = false;

  _ExtractionRemarks: string = "";
  _shortStayFlgTxt: string = "";
  InvoiceMaxDate: Date;

  constructor(private http: Httpclient,
    private route: ActivatedRoute,
    private router: Router, 
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private spinner: SpinnerVisibilityService,
    private cd: ChangeDetectorRef) { 
     
    }

  ngOnInit(): void {
  
    setTimeout(()=>{   
         
      $("#GSTHeader").hide();
   
      }, 5000);

  // //   var timeleft = 5;
  // //   setTimeout(()=>{   
  // //     introJs().setOptions({
  // //       exitOnOverlayClick: true,
  // //       showBullets: true,
  // //       showStepNumbers: false,
  // //     }).oncomplete(()=>{
  // //       setTimeout(()=>{   
         
  // //         $("#GSTHeader").hide();
       
  // //         }, 5000);
  // //         timeleft = 5;
  // //     var downloadTimer = setInterval(function(){
  // //   if(timeleft <= 0){
  // //     clearInterval(downloadTimer);
  // //    // document.getElementById("countdown").innerHTML = "";
  // //   } else {
  // //    // document.getElementById("countdown").innerHTML = timeleft + "";
  // //   }
  // //   timeleft -= 1;
  // // }, 1000);
  // //     })
  // //     .onexit(function() {
  // //       setTimeout(()=>{   
         
  // //         $("#GSTHeader").hide();
       
  // //         }, 5000);
  // //         timeleft = 5;
  // //     var downloadTimer = setInterval(function(){
  // //   if(timeleft <= 0){
  // //     clearInterval(downloadTimer);
  // //    // document.getElementById("countdown").innerHTML = "";
  // //   } else {
  // //    // document.getElementById("countdown").innerHTML = timeleft + "";
  // //   }
  // //   timeleft -= 1;
  // // }, 1000);
  // //     }).start();
    
  // //   }, 1000);
    
    
    this.TotalSelectedPOAmount = "0";
    this.TotalInvoiceAmount = "0";
    this.TotalTariffInvoiceAmount = "0";
    
    this.RowId = this.route.snapshot.paramMap.get('rowid'); 
    if(this.RowId == null)
    {
      debugger;
      this.RowId = localStorage.getItem("_PropertyuniqueId"); 
    }
    
    localStorage.setItem("_PropertyuniqueId",this.RowId);
    if(this.RowId == "null" || this.RowId ==null )
    {
      window.location.href = environment.BaseUrl + "#/error404";

    }
    this.spinner.show()
    this.Orders =[];

    this.cols = [
      { field: 'BookingCode', header: 'HB Booking Code' }, 
      { field: 'PropertyRef', header: 'Hotel Ref No' },
      { field: 'POAmount_PerDay', header: 'Tariff / Day' }, 
      { field: 'StayDays', header: 'Stay Days' }, 
      { field: 'POAmount', header: 'Total Tariff' }, 
      { field: 'ServiceAmount', header: 'Total Food / Service' },       
      { field: 'BalanceAmount', header: 'Balance Value' },       
      { field: 'GuestName', header: 'Guest Name' },  
      { field: 'ChkOutStay', header: 'Stay Duration' },
      { field: 'Chk', header: 'Upload Invoice' },
   
  ];
  this.Basedons = [
    {name: 'Check-In Date', selectedBasedon: 'Check-In Date'},
    {name: 'Check-Out Date', selectedBasedon: 'Check-Out Date'}
    
   
];
this.selectedBasedon = 
  {name: 'Check-In Date', selectedBasedon: 'Check-In Date'}

  
    var mapDtls = { 
      FromDt: "",
      ToDt: "",
      RowId:this.RowId,
      Basedon:this.selectedBasedon.selectedBasedon,
      UserId: this.UserId
    }
    
    setTimeout(()=>{   
    this.http.post("getbookingdetails", mapDtls).toPromise().then(res => {
      debugger
      const parseJson =JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson); 
      this.OrdersTmp =  JSON.stringify(res);
      this.Orders = parsedJson.PoDetails[0];
      this.HotelName = parsedJson.PoDetails[1][0].PropertyName;
      this._HotelId = parsedJson.PoDetails[1][0].Id;
      this.HotelGstNumber = parsedJson.PoDetails[1][0].GSTNumber;
      this.HotelAddress = parsedJson.PoDetails[1][0].Propertaddress;
      this.HBName = parsedJson.PoDetails[2][0].CompanyName;
      this.HBGstNumber = parsedJson.PoDetails[2][0].GSTNumber;
      this.HBAddress = parsedJson.PoDetails[2][0].HBAddress;
      this.SubmittedOn = parsedJson.PoDetails[3][0].CurrentDate;
      this.TentativePayment = parsedJson.PoDetails[3][0].TentativeDate;

      var date2 = this.SubmittedOn.split('/');
      var date3 = date2[1] + '/' + date2[0] + '/' + date2[2]; 

      this.InvoiceMaxDate = new Date(date3);

      this.spinner.hide();
    }).catch(err => {      
      this.spinner.hide(); 
      return err;
    });
  }, 200);

  
 this.EndDate = new Date();

 

  }
 
  
  FnSearch() {
    this.Orders = [];
    this.OrdersTmp = [];
    this.SelectedPoDetails = [];
    this.TotalSelectedPOAmount = "0";
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg="";
    
    if( this.StartDate == undefined) { 
      toastOptions.msg += 'From Date is required' + '</br>';
    }
    if( this.EndDate == undefined) { 
      toastOptions.msg += 'To Date is required' + '</br>';
    }
   
    if (toastOptions.msg != "") { 
      this.toastyService.error(toastOptions);
    }
    else { 
     
      var dd = this.StartDate.getDate();
      var mm = this.StartDate.getMonth()+1;
      var yy = this.StartDate.getFullYear();
      var StartDate =dd +"/" + mm+"/" + yy;
      var dd = this.EndDate.getDate();
      var mm = this.EndDate.getMonth()+1;
      var yy = this.EndDate.getFullYear();
      var EndDate =dd +"/" + mm+"/" + yy;
    var mapDtls = { 
      FromDt: StartDate,
      ToDt: EndDate,
      RowId:this.RowId,
      Basedon:this.selectedBasedon.selectedBasedon,
      UserId: this.UserId
    }
    
    return this.http.post("getbookingdetails", mapDtls).toPromise().then(res => {
      debugger
      const parseJson =JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson); 
      this.OrdersTmp =  JSON.stringify(res);
      this.Orders = parsedJson.PoDetails[0];
      this.spinner.hide();
    }).catch(err => {      
      this.spinner.hide(); 
      return err;
    });
    }
  }
  
  TotalInvoice() 
  {
    debugger;
    this.TotalInvoiceAmount = "0";

    var TempVendorTariff = 0;
    var TempVendorServiceAmount = 0;
    var TempCGSTAmount = 0;
    var TempSGSTAmount = 0;
    var TempVendorServiceCGST = 0;
    var TempVendorServiceSGST = 0;

    if(this.VendorTariff == undefined || this.VendorTariff == "")
    {
      TempVendorTariff = 0;
    }
    else {
      TempVendorTariff = parseFloat(this.VendorTariff);
    }
    if(this.CGSTAmount == undefined || this.CGSTAmount == "")
    {
      TempCGSTAmount = 0;
    }
    else {
      TempCGSTAmount = parseFloat(this.CGSTAmount);
    }
    if(this.SGSTAmount == undefined || this.SGSTAmount == "")
    {
      TempSGSTAmount = 0;
    }
    else {
      TempSGSTAmount = parseFloat(this.SGSTAmount);
    }
      


    if(this.VendorServiceAmount == undefined || this.VendorServiceAmount == "")
    {
      TempVendorServiceAmount = 0;  
    } 
    else {
      TempVendorServiceAmount = parseFloat(this.VendorServiceAmount);
    }

    if(this.VendorServiceCGST == undefined || this.VendorServiceCGST == "")
    {
      TempVendorServiceCGST = 0;  
    } 
    else {
      TempVendorServiceCGST = parseFloat(this.VendorServiceCGST);
    }

    if(this.VendorServiceSGST == undefined || this.VendorServiceSGST == "")
    {
      TempVendorServiceSGST = 0;  
    } 
    else {
      TempVendorServiceSGST = parseFloat(this.VendorServiceSGST);
    }


    var Tariff = parseFloat(TempVendorTariff.toString());
    var ServiceAmount = parseFloat(TempVendorServiceAmount.toString());
    var SGSTAmt = parseFloat(TempCGSTAmount.toString());
    var CGSTAmt = parseFloat(TempSGSTAmount.toString());
    var ServiceCGSTAmt = parseFloat(TempVendorServiceCGST.toString());
    var ServiceSGSTAmt = parseFloat(TempVendorServiceSGST.toString());
    
    this.TotalTariffInvoiceAmount = (Tariff+SGSTAmt+CGSTAmt).toString();
    this.TotalServiceInvoiceAmount  = (ServiceAmount+ServiceCGSTAmt+ServiceSGSTAmt).toString();

    this.TotalInvoiceAmount = (Tariff+ServiceAmount+SGSTAmt+CGSTAmt+ServiceCGSTAmt+ServiceSGSTAmt).toString();


  }
  StartDateSelected(StartDate)
  {
    let datePlus = new Date(StartDate).getDate() + 1;
    this.EndDate = new Date(StartDate);
    this.EndDate.setDate(datePlus)

  }

  onBasicUploadAutoPdf(event,data,source) {
    debugger
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";
    
    if (event.originalEvent.body.message =="Only single-page PDFs are permitted for upload." ){
      toastOptions.msg += 'Only single-page invoice are permitted for upload.' + '</br>';
      this.toastyService.error(toastOptions);
      return false;
    }
    else if(event.originalEvent.body.message =="The invoice has not been successfully uploaded.") {
      toastOptions.msg += 'The invoice has not been successfully uploaded.' + '</br>';
      this.toastyService.error(toastOptions);
      return false;
    }
    else if(event.originalEvent.body.message =="Invoice is not in proper format. Please upload the correct invoice.") {
      toastOptions.msg += 'Invoice is not in proper format. Please upload the correct invoice.' + '</br>';
      this.toastyService.error(toastOptions);
      return false;
    }
    else if(event.originalEvent.body.message =="Invoice uploaded successfully.") {



      if(source=='grid') {
  
        debugger
        this._selectedPo = [];
        this._InvoiceDetails =[];

        this._selectedPo = data;
        this._InvoiceDetails = event.originalEvent.body.InvoiceRes;

    

        this._ReservationDetailsflg = false;
        this._InvoiceDetailsflg = true;
        this._Tariffdetailsflg = true;
        if(this._selectedPo.ServiceAmount > 0) {
          this._ServiceDetailsflg = true;
        }
        else {
          this._ServiceDetailsflg = false;
        }
        
        if(Object.keys(this._InvoiceDetails).length != 0) {
        // Invoice No
        this.InvoiceNo = this._InvoiceDetails.BillNo; 

        var date1 = this._InvoiceDetails.Invoice_Date;

        if(date1!="" && date1!=null && date1!=undefined && date1!="01/01/1900") {                
          var date2 = date1.split('/');
          var date3 = date2[1] + '/' + date2[0] + '/' + date2[2];
          this.InvoiceDate = new Date(date3); 
        }
        else {
          this.InvoiceDate = new Date(); 
        }

        this._tariffFileUrl = event.originalEvent.body.path;
        this.TraiffFileName = this._tariffFileUrl.replace('https://uploadinvoices.blob.core.windows.net/hotelmappinginvoices/','');
   
        if (this._selectedPo.BalanceAmount == this._InvoiceDetails.Total_amt ||
          (((this._selectedPo.BalanceAmount + 100) >= this._InvoiceDetails.Total_amt) && (this._selectedPo.BalanceAmount <= this._InvoiceDetails.Total_amt)) ||
          (((this._selectedPo.BalanceAmount - 100) <= this._InvoiceDetails.Total_amt) && (this._selectedPo.BalanceAmount >= this._InvoiceDetails.Total_amt))) {
          this.NoofDays = (this._selectedPo.Remaining_StayDays).toString();
        }
        else {
          this.NoofDays ='';
        }
         
        this.VendorTariff = this._InvoiceDetails.Stay_tariff == undefined ? "" : this._InvoiceDetails.Stay_tariff;
  
        this.CGSTAmount = this._InvoiceDetails.Total_CGST== undefined ? "" : this._InvoiceDetails.Total_CGST;
        this.SGSTAmount = this._InvoiceDetails.Total_SGST== undefined ? "" : this._InvoiceDetails.Total_SGST

        this.TotalTariffInvoiceAmount = this._InvoiceDetails.Total_amt== undefined ? "0" : this._InvoiceDetails.Total_amt;


        this.TotalInvoiceAmount = this._InvoiceDetails.Total_amt== undefined ? "0" : this._InvoiceDetails.Total_amt;

        }
        else {
         this.InvoiceNo ="";
         this.InvoiceDate = new Date(); 

         this._tariffFileUrl = event.originalEvent.body.path;
         this.TraiffFileName = this._tariffFileUrl.replace('https://uploadinvoices.blob.core.windows.net/hotelmappinginvoices/','');
    
         this.VendorTariff = "";
         this.NoofDays ='';
         this.CGSTAmount = "";
         this.SGSTAmount = "";
         this.TotalTariffInvoiceAmount = "0";
         this.TotalInvoiceAmount = "0";

        }
  
        this.displayModal = true;
      }
      else if(source=='tariff'){
        this._tariffFileUrl = event.originalEvent.body.path;
        this.TraiffFileName = this._tariffFileUrl.replace('https://uploadinvoices.blob.core.windows.net/hotelmappinginvoices/','');
        this._InvoiceDetails =[];
        this._InvoiceDetails = event.originalEvent.body.InvoiceRes;
        
        if(Object.keys(this._InvoiceDetails).length != 0) {
          // Invoice No
          this.InvoiceNo = this._InvoiceDetails.BillNo; 
  
          var date1 = this._InvoiceDetails.Invoice_Date;
  
          if(date1!="" && date1!=null && date1!=undefined && date1!="01/01/1900") {              
            var date2 = date1.split('/');
            var date3 = date2[1] + '/' + date2[0] + '/' + date2[2];
            this.InvoiceDate = new Date(date3); 
          }
          else {
            this.InvoiceDate = new Date(); 
          }
  
        
          if (this._selectedPo.BalanceAmount == this._InvoiceDetails.Total_amt ||
            (((this._selectedPo.BalanceAmount + 100) >= this._InvoiceDetails.Total_amt) && (this._selectedPo.BalanceAmount <= this._InvoiceDetails.Total_amt)) ||
            (((this._selectedPo.BalanceAmount - 100) <= this._InvoiceDetails.Total_amt) && (this._selectedPo.BalanceAmount >= this._InvoiceDetails.Total_amt))) {
            this.NoofDays = (this._selectedPo.Remaining_StayDays).toString();
          }
          else {
            this.NoofDays ='';
          }
           
          this.VendorTariff = this._InvoiceDetails.Stay_tariff == undefined ? "" : this._InvoiceDetails.Stay_tariff;
  
          this.CGSTAmount = this._InvoiceDetails.Total_CGST== undefined ? "" : this._InvoiceDetails.Total_CGST;
          this.SGSTAmount = this._InvoiceDetails.Total_SGST== undefined ? "" : this._InvoiceDetails.Total_SGST
  
          this.TotalTariffInvoiceAmount = this._InvoiceDetails.Total_amt== undefined ? "0" : this._InvoiceDetails.Total_amt;
  
          this.TotalInvoiceAmount = (parseFloat(this.TotalTariffInvoiceAmount) + parseFloat(this.TotalServiceInvoiceAmount)).toString();
          }
          else {
           this.InvoiceNo ="";
           this.InvoiceDate = new Date(); 
   
           this.VendorTariff = "";
           this.NoofDays ='';
           this.CGSTAmount = "";
           this.SGSTAmount = "";
           this.TotalTariffInvoiceAmount = "0";
           this.TotalInvoiceAmount = (0 + parseFloat(this.TotalServiceInvoiceAmount)).toString();
  
          }


      } 
      else {
        this._serviceFileUrl = event.originalEvent.body.path;
        this.ServiceFileName = this._serviceFileUrl.replace('https://uploadinvoices.blob.core.windows.net/hotelmappinginvoices/','');
        this.ServiceInvoiceNo ="";
      }
   
      toastOptions.msg += 'Invoice uploaded successfully.';
      this.toastyService.success(toastOptions);
    }
    else {
      toastOptions.msg += 'The invoice has not been successfully uploaded.' + '</br>';
      this.toastyService.error(toastOptions);
      return false;
    }

    
    
    
  }
  onBasicUploadAutoPdfCheck(event,data) {
    debugger
    var toastOptions:ToastOptions = {
      title: "Alert", 
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast:ToastData) => {
      },
      onRemove: function(toast:ToastData) {
      }
  };
  toastOptions.msg=""; 
  debugger;
    if(event.files[0].type =='application/pdf' || event.files[0].type =='image/jpeg' || event.files[0].type =='image/png' ) {
      
    } 
    else {
      toastOptions.msg += 'File type is invalid' + '</br>';
    }

    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions); 
      return false;
    }
    else {
      var selectedPo = data;
      if(selectedPo.ServiceAmount==0){
         this._ServiceAmountFlg = false
      }
      else {
        this._ServiceAmountFlg = true;
      }
    }
 
  }

  onSelect(){
    debugger;
    if(this.rangeDates[1] !==null)
    {
      
      $("#closed").trigger("click");
    }
    
  }

  SelectRow(event)
  {   
    debugger;
      for(var i=0;i< this.Orders.length;i++)
      {

       if(this.Orders[i].BookingId == event.BookingId && this.Orders[i].GuestId == event.GuestId && event.Chk == false)
        {
          this.Orders[i].Chk = true;
        }
        else if(this.Orders[i].BookingId == event.BookingId && this.Orders[i].GuestId == event.GuestId && event.Chk == true)
        {
          this.Orders[i].Chk = false;
        }
      }
      this.TotalSelectedPOAmount = "0";
      this.SelectedPoDetails = [];
      for(var i =0;i < this.Orders.length;i++)
     {   
        if(this.Orders[i].Chk == true)
        {
          this.TotalSelectedPOAmount = (parseFloat(this.TotalSelectedPOAmount)+ parseFloat(this.Orders[i].BalanceAmount)).toString();

          this.Orders[i].ShortStay = true;
          this.Orders[i].StayExtend = true; 

          this.SelectedPoDetails.push(this.Orders[i]);
        }
      }
  
  }

  Continue() {
    debugger;
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";

    var shortStayFlg = false;

    var ServiceErrFlg = false;
    var DaysMatchFlg = false;

    if (this.InvoiceNo == undefined || this.InvoiceNo.trim() == '') {
      toastOptions.msg += 'Invoice No is required' + '</br>';
    }
    if (this.InvoiceDate == undefined) {
      toastOptions.msg += 'Invoice Date is required' + '</br>';
    }
    else {
      debugger
      var date1 = this.InvoiceDate;
      var date2 = this._selectedPo.BookedDt;

      var date3 = date2.split('/');
      var date4 = date3[1] + '/' + date3[0] + '/' + date3[2];
      var date5 = new Date(date4);

      if (date1 < date5) {
        toastOptions.msg += 'Invoice Date should be equal to or greater than ' + this._selectedPo.BookedDt + ' (booked date).' + '</br>';
      }
    }
    if (this.NoofDays == undefined || this.NoofDays.trim() == '' || this.NoofDays == null) {
      toastOptions.msg += 'No.of Stay Days is required' + '</br>';
    }
    else if (this.NoofDays.toString().match(this.regex) == null) {
      toastOptions.msg += 'No.of Stay Days is invalid' + '</br>';
    }
    else {
      var tt = parseFloat(this.NoofDays);
      if (tt < 1) {
        toastOptions.msg += 'No.of Stay Days is minimum 1 day required' + '</br>';
      }
      else {
        if (this._selectedPo.Remaining_StayDays < tt) {
          toastOptions.msg += 'The number of stay days should be less than or equal to ' + this._selectedPo.Remaining_StayDays + ' day(s) ' + '</br>';
        }
        else if (this._selectedPo.Remaining_StayDays > tt) {
          shortStayFlg = true
        }
        else {
          DaysMatchFlg=true;
        }
      }
    }
    if (this._tariffFileUrl == undefined || this._tariffFileUrl.trim() == '') {
      toastOptions.msg += 'Invoice Upload is required' + '</br>';
    }

    if (this.VendorTariff == null || this.VendorTariff == undefined || this.VendorTariff == "" || this.VendorTariff == "0") {
      toastOptions.msg += 'Total Tariff is required' + '</br>';
    }
    else {
      if (this.VendorTariff.toString().match(this.regex) == null) {
        toastOptions.msg += 'Total Tariff is invalid' + '</br>';
      }
    }

    if (this.CGSTAmount == null || this.CGSTAmount == undefined || this.CGSTAmount == "") {
      toastOptions.msg += 'Total Tariff CGST is required' + '</br>';
    }
    else {
      if (this.CGSTAmount.toString().match(this.regex) == null) {
        toastOptions.msg += 'Total Tariff CGST is invalid' + '</br>';
      }
    }

    if (this.SGSTAmount == null || this.SGSTAmount == undefined || this.SGSTAmount == "") {
      toastOptions.msg += 'Total Tariff SGST is required' + '</br>';
    }
    else {
      if (this.SGSTAmount.toString().match(this.regex) == null) {
        toastOptions.msg += 'Total Tariff SGST is invalid' + '</br>';
      }
    }


    if (toastOptions.msg != '') {

      toastOptions.msg = '<b>Tariff Details: </b>' + '</br>' + toastOptions.msg;

    }


    if (this._selectedPo.ServiceAmount > 0) {

      if (this._serviceFileUrl == undefined || this._serviceFileUrl.trim() == '') {
        this._serviceFileUrl = "";
      }
      else {
        if (this.ServiceInvoiceNo == undefined || this.ServiceInvoiceNo.trim() == '') {
          if (ServiceErrFlg == false) {
            ServiceErrFlg = true;
            toastOptions.msg += '</br><b>Service Details: </b>' + '</br>';
          }
          toastOptions.msg += 'Invoice No is required' + '</br>';
        }
      }

      if (this._serviceFileUrl == "" && this.ServiceInvoiceNo != "") {
        if (ServiceErrFlg == false) {
          ServiceErrFlg = true;
          toastOptions.msg += '</br><b>Service Details: </b>' + '</br>';
        }
        toastOptions.msg += 'Invoice Upload is required' + '</br>';
      }

      if (this.VendorServiceAmount == null || this.VendorServiceAmount == undefined || this.VendorServiceAmount == "" || this.VendorServiceAmount == "0") {
        if (ServiceErrFlg == false) {
          ServiceErrFlg = true;
          toastOptions.msg += '</br><b>Service Details: </b>' + '</br>';
        }
        toastOptions.msg += 'Total Food / Service is required' + '</br>';
      }
      else {
        if (this.VendorServiceAmount.toString().match(this.regex) == null) {
          if (ServiceErrFlg == false) {
            ServiceErrFlg = true;
            toastOptions.msg += '</br><b>Service Details: </b>' + '</br>';
          }
          toastOptions.msg += 'Total Food / Service is invalid' + '</br>';
        }
      }

      if (this.VendorServiceCGST == null || this.VendorServiceCGST == undefined || this.VendorServiceCGST == "") {
        if (ServiceErrFlg == false) {
          ServiceErrFlg = true;
          toastOptions.msg += '</br><b>Service Details: </b>' + '</br>';
        }
        toastOptions.msg += 'Total Food / Service CGST is required' + '</br>';
      }
      else {
        if (this.VendorServiceCGST.toString().match(this.regex) == null) {
          if (ServiceErrFlg == false) {
            ServiceErrFlg = true;
            toastOptions.msg += '</br><b>Service Details: </b>' + '</br>';
          }
          toastOptions.msg += 'Total Food / Service CGST is invalid' + '</br>';
        }
      }

      if (this.VendorServiceSGST == null || this.VendorServiceSGST == undefined || this.VendorServiceSGST == "") {
        if (ServiceErrFlg == false) {
          ServiceErrFlg = true;
          toastOptions.msg += '</br><b>Service Details: </b>' + '</br>';
        }
        toastOptions.msg += 'Total Food / Service SGST is required' + '</br>';
      }
      else {
        if (this.VendorServiceSGST.toString().match(this.regex) == null) {
          if (ServiceErrFlg == false) {
            ServiceErrFlg = true;
            toastOptions.msg += '</br><b>Service Details: </b>' + '</br>';
          }
          toastOptions.msg += 'Total Food / Service SGST is invalid' + '</br>';
        }
      }



    }
    else {

      if (this._serviceFileUrl == undefined || this._serviceFileUrl == null) {
        this._serviceFileUrl = "";
      }
      if (this.ServiceInvoiceNo == undefined || this.ServiceInvoiceNo == null) {
        this.ServiceInvoiceNo = "";
      }
      if (this.VendorServiceAmount == null || this.VendorServiceAmount == undefined || this.VendorServiceAmount == "" || this.VendorServiceAmount == "0") {
        this.VendorServiceAmount = "";
      }
      if (this.VendorServiceCGST == null || this.VendorServiceCGST == undefined || this.VendorServiceCGST == "" || this.VendorServiceCGST == "0") {
        this.VendorServiceCGST = "";
      }
      if (this.VendorServiceSGST == null || this.VendorServiceSGST == undefined || this.VendorServiceSGST == "" || this.VendorServiceSGST == "0") {
        this.VendorServiceSGST = "";
      }
      if (this.TotalServiceInvoiceAmount == null || this.TotalServiceInvoiceAmount == undefined || this.TotalServiceInvoiceAmount == "") {
        this.TotalServiceInvoiceAmount = "0";
      }

      if (this._serviceFileUrl != "" || this.ServiceInvoiceNo != "" || this.VendorServiceAmount != "" || this.VendorServiceCGST != "" || this.VendorServiceSGST != "") {
        if (ServiceErrFlg == false) {
          ServiceErrFlg = true;
          toastOptions.msg += '</br><b>Service Details: </b>' + '</br>';
        }
        toastOptions.msg += "Selected PO doesn't have a Service Amount, so service details are not allowed." + '</br>';
      }
    }









    debugger

    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
    }
    else {

      //Service Amount Validation
      var ServiceEntryFlg = false;
      var ServiceSameInvoiceFlg = true;


      //Total Amount Validation
      var BalanceAmount = parseFloat(this._selectedPo.BalanceAmount);

      var InvoiceAmount = parseFloat(this.TotalInvoiceAmount);
      var InvoiceAmountTariff = parseFloat(this.TotalTariffInvoiceAmount);
      var InvoiceAmountService = parseFloat(this.TotalServiceInvoiceAmount);

      var TempVendorTariff;
      var TempCGSTAmount;
      var TempSGSTAmount;
      var TempVendorServiceAmount;
      var TempVendorServiceCGST;
      var TempVendorServiceSGST;

      if (this.VendorTariff == undefined || this.VendorTariff == "") {
        TempVendorTariff = 0;
      }
      else {
        TempVendorTariff = parseFloat(this.VendorTariff);
      }
      if (this.CGSTAmount == undefined || this.CGSTAmount == "") {
        TempCGSTAmount = 0;
      }
      else {
        TempCGSTAmount = parseFloat(this.CGSTAmount);
      }
      if (this.SGSTAmount == undefined || this.SGSTAmount == "") {
        TempSGSTAmount = 0;
      }
      else {
        TempSGSTAmount = parseFloat(this.SGSTAmount);
      }



      if (this.VendorServiceAmount == undefined || this.VendorServiceAmount == "") {
        TempVendorServiceAmount = 0;
      }
      else {
        TempVendorServiceAmount = parseFloat(this.VendorServiceAmount);
      }

      if (this.VendorServiceCGST == undefined || this.VendorServiceCGST == "") {
        TempVendorServiceCGST = 0;
      }
      else {
        TempVendorServiceCGST = parseFloat(this.VendorServiceCGST);
      }

      if (this.VendorServiceSGST == undefined || this.VendorServiceSGST == "") {
        TempVendorServiceSGST = 0;
      }
      else {
        TempVendorServiceSGST = parseFloat(this.VendorServiceSGST);
      }


      if ((TempVendorTariff + TempCGSTAmount + TempSGSTAmount) != InvoiceAmountTariff) {
        toastOptions.msg += 'Total Tariff Amount should be sum of Total Tariff,CGST and SGST </br>';
      }

      if (toastOptions.msg != "") {
        this.toastyService.error(toastOptions);
      }
      else {

        var WriteOffAmount = 0;
        this._ExtractionRemarks = "";
        debugger
        if (!shortStayFlg) {
          if (InvoiceAmountService == 0) {
            if (BalanceAmount == InvoiceAmountTariff) {
              if (Object.keys(this._InvoiceDetails).length != 0) {
                if (InvoiceAmountTariff == this._InvoiceDetails.Total_amt) {
                  this._ExtractionStatus = "verified";
                }
                else {
                  this._ExtractionStatus = "verified";
                  this._ExtractionRemarks += "Invoice Amount Mismatch. ";
                }
              }
              else {
                this._ExtractionStatus = "verified";
                this._ExtractionRemarks += "Invoice Not Extracted. ";
              }
            }
            else if (BalanceAmount > InvoiceAmountTariff) {
              if (BalanceAmount > InvoiceAmountTariff && InvoiceAmountTariff >= (BalanceAmount - 100)) {

                if (Object.keys(this._InvoiceDetails).length != 0) {
                  if (InvoiceAmountTariff == this._InvoiceDetails.Total_amt) {
                    this._ExtractionStatus = "verified";
                  }
                  else {
                    this._ExtractionStatus = "verified";
                    this._ExtractionRemarks += "Invoice Amount Mismatch. ";
                  }
                }
                else {
                  this._ExtractionStatus = "verified";
                  this._ExtractionRemarks += "Invoice Not Extracted. ";
                }
                this._WriteOffAmount = (BalanceAmount - InvoiceAmountTariff).toString();
                this._WriteOffFlg = true;
              }
              else {
                toastOptions.msg += 'Total Invoice Amount should be less than or equal to ' + this._selectedPo.BalanceAmount + '</br>';
              }
            }
            else if (BalanceAmount < InvoiceAmountTariff) {
              if (BalanceAmount < InvoiceAmountTariff && InvoiceAmountTariff <= (BalanceAmount + 100)) {

                if (Object.keys(this._InvoiceDetails).length != 0) {
                  if (InvoiceAmountTariff == this._InvoiceDetails.Total_amt) {
                    this._ExtractionStatus = "verified"
                  }
                  else {
                    this._ExtractionStatus = "verified";
                    this._ExtractionRemarks += "Invoice Amount Mismatch. ";
                  }
                }
                else {
                  this._ExtractionStatus = "verified";
                  this._ExtractionRemarks += "Invoice Not Extracted. ";
                }
              }
              else {
                toastOptions.msg += 'Total Invoice Amount should be less than or equal to ' + (this._selectedPo.BalanceAmount) + '</br>';
              }

            }
          }
          else {

            if (this.TotalServiceInvoiceAmount != "0" && this.TotalServiceInvoiceAmount != "" && this.TotalServiceInvoiceAmount != undefined && this.TotalServiceInvoiceAmount != null) {
              if (this._selectedPo.ServiceAmount < (parseFloat(this.VendorServiceAmount) + parseFloat(this.VendorServiceCGST) + parseFloat(this.VendorServiceSGST))) {
                toastOptions.msg += 'Service Amount should be less than or equal to ' + this._selectedPo.ServiceAmount + '</br>';
              }
              else {
                ServiceEntryFlg = true;
                if (this.ServiceInvoiceNo != "" && this.ServiceInvoiceNo != undefined && this.ServiceInvoiceNo != null) {
                  ServiceSameInvoiceFlg = false;
                }

                var TotalPoAmt = parseFloat((parseFloat(this._selectedPo.POAmount_PerDay) * parseInt(this.NoofDays)).toFixed(2));


                if (TotalPoAmt == InvoiceAmountTariff) {

                  if(InvoiceAmount <= (BalanceAmount + 100)) 
                  {

                    if (Object.keys(this._InvoiceDetails).length != 0) {
                      if (InvoiceAmountTariff == this._InvoiceDetails.Total_amt) {
                        this._ExtractionStatus = "verified"
                      }
                      else {
                        this._ExtractionStatus = "verified";
                        this._ExtractionRemarks += "Invoice Amount Mismatch. ";
                      }
                    }
                    else {
                      this._ExtractionStatus = "verified";
                      this._ExtractionRemarks += "Invoice Not Extracted. ";
                    }

                    if(DaysMatchFlg==true) {
                      if (this._selectedPo.ServiceAmount > (parseFloat(this.VendorServiceAmount) + parseFloat(this.VendorServiceCGST) + parseFloat(this.VendorServiceSGST))) {
                        this._WriteOffAmount = (this._selectedPo.ServiceAmount - (parseFloat(this.VendorServiceAmount) + parseFloat(this.VendorServiceCGST) + parseFloat(this.VendorServiceSGST))).toString();
                        this._WriteOffFlg = true;
                      }
                    }
                 

                  }
                  else {
                    toastOptions.msg += 'Total Invoice Amount should be less than or equal to ' + BalanceAmount + '</br>';
                  }


                }
                else if (TotalPoAmt > InvoiceAmountTariff) {

                  if (InvoiceAmount <= (BalanceAmount + 100)) {

                    if (TotalPoAmt > InvoiceAmountTariff && InvoiceAmountTariff >= (TotalPoAmt - 100)) {

                      if (Object.keys(this._InvoiceDetails).length != 0) {
                        if (InvoiceAmountTariff == this._InvoiceDetails.Total_amt) {
                          this._ExtractionStatus = "verified"
                        }
                        else {
                          this._ExtractionStatus = "verified";
                          this._ExtractionRemarks += "Invoice Amount Mismatch. ";
                        }
                      }
                      else {
                        this._ExtractionStatus = "verified";
                        this._ExtractionRemarks += "Invoice Not Extracted. ";
                      }
                      if(BalanceAmount > InvoiceAmount && DaysMatchFlg==true) {
                        this._WriteOffAmount = (BalanceAmount - InvoiceAmount).toString();
                        this._WriteOffFlg = true;
                      }

                    }
                    else {
                      toastOptions.msg += 'Total Tariff Amount should be less than or equal to ' + TotalPoAmt + '</br>';
                    }
                  }
                  else {
                    toastOptions.msg += 'Total Invoice Amount should be less than or equal to ' + BalanceAmount + '</br>';
                  }
                }
                else if (TotalPoAmt < InvoiceAmountTariff) {

                  if (InvoiceAmount <= (BalanceAmount + 100)) {
                    if (TotalPoAmt < InvoiceAmountTariff && InvoiceAmountTariff <= (TotalPoAmt + 100)) {
    
                      if (Object.keys(this._InvoiceDetails).length != 0) {
                        if (InvoiceAmountTariff == this._InvoiceDetails.Total_amt) {
                          this._ExtractionStatus = "verified"
                        }
                        else {
                          this._ExtractionStatus = "verified";
                          this._ExtractionRemarks += "Invoice Amount Mismatch. ";
                        }
                      }
                      else {
                        this._ExtractionStatus = "verified";
                        this._ExtractionRemarks += "Invoice Not Extracted. ";
                      }
                      
                      if(BalanceAmount > InvoiceAmount && DaysMatchFlg==true) {
                        this._WriteOffAmount = (BalanceAmount - InvoiceAmount).toString();
                        this._WriteOffFlg = true;
                      }
                    
                      
                    }
                    else {
                      toastOptions.msg += 'Total Tariff Amount should be less than or equal to ' + (TotalPoAmt) + '</br>';
                    }
                

                  }
                  else {
                    toastOptions.msg += 'Total Invoice Amount should be less than or equal to ' + BalanceAmount + '</br>';
                  }

           
    
                }




              }
            }




          }
        }
        else {
          debugger
          var TotalPoAmt = parseFloat((parseFloat(this._selectedPo.POAmount_PerDay) * parseInt(this.NoofDays)).toFixed(2));

          if (InvoiceAmountService == 0) {

            if (TotalPoAmt == InvoiceAmountTariff) {
              if (Object.keys(this._InvoiceDetails).length != 0) {
                if (InvoiceAmountTariff == this._InvoiceDetails.Total_amt) {
                  this._ExtractionStatus = "verified"
                }
                else {
                  this._ExtractionStatus = "verified";
                  this._ExtractionRemarks += "Invoice Amount Mismatch. ";
                }
              }
              else {
                this._ExtractionStatus = "verified";
                this._ExtractionRemarks += "Invoice Not Extracted. ";
              }
            }
            else if (TotalPoAmt > InvoiceAmountTariff) {
              if (TotalPoAmt > InvoiceAmountTariff && InvoiceAmountTariff >= (TotalPoAmt - 100)) {

                if (Object.keys(this._InvoiceDetails).length != 0) {
                  if (InvoiceAmountTariff == this._InvoiceDetails.Total_amt) {
                    this._ExtractionStatus = "verified"
                  }
                  else {
                    this._ExtractionStatus = "verified";
                    this._ExtractionRemarks += "Invoice Amount Mismatch. ";
                  }
                }
                else {
                  this._ExtractionStatus = "verified";
                  this._ExtractionRemarks += "Invoice Not Extracted. ";
                }
                this._WriteOffAmount = (TotalPoAmt - InvoiceAmountTariff).toString();
                this._WriteOffFlg = true;
              }
              else {
                toastOptions.msg += 'Total Invoice Amount should be less than or equal to ' + TotalPoAmt + '</br>';
              }
            }
            else if (TotalPoAmt < InvoiceAmountTariff) {
              if (TotalPoAmt < InvoiceAmountTariff && InvoiceAmountTariff <= (TotalPoAmt + 100)) {

                if (Object.keys(this._InvoiceDetails).length != 0) {
                  if (InvoiceAmountTariff == this._InvoiceDetails.Total_amt) {
                    this._ExtractionStatus = "verified"
                  }
                  else {
                    this._ExtractionStatus = "verified";
                    this._ExtractionRemarks += "Invoice Amount Mismatch. ";
                  }
                }
                else {
                  this._ExtractionStatus = "verified";
                  this._ExtractionRemarks += "Invoice Not Extracted. ";
                }
              }
              else {
                toastOptions.msg += 'Total Invoice Amount should be less than or equal to ' + (TotalPoAmt) + '</br>';
              }

            }



          }
          else {

            if (this.TotalServiceInvoiceAmount != "0" && this.TotalServiceInvoiceAmount != "" && this.TotalServiceInvoiceAmount != undefined && this.TotalServiceInvoiceAmount != null) {
             
              if (this._selectedPo.ServiceAmount < (parseFloat(this.VendorServiceAmount) + parseFloat(this.VendorServiceCGST) + parseFloat(this.VendorServiceSGST))) {
                toastOptions.msg += 'Service Amount should be less than or equal to ' + this._selectedPo.ServiceAmount + '</br>';
              }
              else {
                ServiceEntryFlg = true;
                if (this.ServiceInvoiceNo != "" && this.ServiceInvoiceNo != undefined && this.ServiceInvoiceNo != null) {
                  ServiceSameInvoiceFlg = false;
                }


                TotalPoAmt = parseFloat((parseFloat(this._selectedPo.POAmount_PerDay) * parseInt(this.NoofDays)).toFixed(2));


                if (TotalPoAmt == InvoiceAmountTariff) {

                  if(InvoiceAmount <= (BalanceAmount + 100)) 
                  {

                    if (Object.keys(this._InvoiceDetails).length != 0) {
                      if (InvoiceAmountTariff == this._InvoiceDetails.Total_amt) {
                        this._ExtractionStatus = "verified"
                      }
                      else {
                        this._ExtractionStatus = "verified";
                        this._ExtractionRemarks += "Invoice Amount Mismatch. ";
                      }
                    }
                    else {
                      this._ExtractionStatus = "verified";
                      this._ExtractionRemarks += "Invoice Not Extracted. ";
                    }
                    if (this._selectedPo.ServiceAmount > (parseFloat(this.VendorServiceAmount) + parseFloat(this.VendorServiceCGST) + parseFloat(this.VendorServiceSGST))) {
                      this._WriteOffAmount = (this._selectedPo.ServiceAmount - (parseFloat(this.VendorServiceAmount) + parseFloat(this.VendorServiceCGST) + parseFloat(this.VendorServiceSGST))).toString();
                      this._WriteOffFlg = true;
                    }

                  }
                  else {
                    toastOptions.msg += 'Total Invoice Amount should be less than or equal to ' + BalanceAmount + '</br>';
                  }


                }
                else if (TotalPoAmt > InvoiceAmountTariff) {

                  if (InvoiceAmount <= (BalanceAmount + 100)) {

                    if (TotalPoAmt > InvoiceAmountTariff && InvoiceAmountTariff >= (TotalPoAmt - 100)) {

                      if (Object.keys(this._InvoiceDetails).length != 0) {
                        if (InvoiceAmountTariff == this._InvoiceDetails.Total_amt) {
                          this._ExtractionStatus = "verified"
                        }
                        else {
                          this._ExtractionStatus = "verified";
                          this._ExtractionRemarks += "Invoice Amount Mismatch. ";
                        }
                      }
                      else {
                        this._ExtractionStatus = "verified";
                        this._ExtractionRemarks += "Invoice Not Extracted. ";
                      }
                   
                      if (this._selectedPo.ServiceAmount > (parseFloat(this.VendorServiceAmount) + parseFloat(this.VendorServiceCGST) + parseFloat(this.VendorServiceSGST))) {
                        
                        this._WriteOffAmount = (this._selectedPo.ServiceAmount - (parseFloat(this.VendorServiceAmount) + parseFloat(this.VendorServiceCGST) + parseFloat(this.VendorServiceSGST))).toString();
                        
                        this._WriteOffAmount = ((parseFloat(this._WriteOffAmount) + (TotalPoAmt - InvoiceAmountTariff))).toString();

                        this._WriteOffFlg = true;
                      }
                      else {
                        this._WriteOffAmount = (TotalPoAmt - InvoiceAmountTariff).toString();
                        this._WriteOffFlg = true;
                      }


                    }
                    else {
                      toastOptions.msg += 'Total Tariff Amount should be less than or equal to ' + TotalPoAmt + '</br>';
                    }
                  }
                  else {
                    toastOptions.msg += 'Total Invoice Amount should be less than or equal to ' + BalanceAmount + '</br>';
                  }
                }
                else if (TotalPoAmt < InvoiceAmountTariff) {

                  if (InvoiceAmount <= (BalanceAmount + 100)) {
                    if (TotalPoAmt < InvoiceAmountTariff && InvoiceAmountTariff <= (TotalPoAmt + 100)) {
    
                      if (Object.keys(this._InvoiceDetails).length != 0) {
                        if (InvoiceAmountTariff == this._InvoiceDetails.Total_amt) {
                          this._ExtractionStatus = "verified"
                        }
                        else {
                          this._ExtractionStatus = "verified";
                          this._ExtractionRemarks += "Invoice Amount Mismatch. ";
                        }
                      }
                      else {
                        this._ExtractionStatus = "verified";
                        this._ExtractionRemarks += "Invoice Not Extracted. ";
                      }
                      
                      if (this._selectedPo.ServiceAmount > (parseFloat(this.VendorServiceAmount) + parseFloat(this.VendorServiceCGST) + parseFloat(this.VendorServiceSGST))) {
                        this._WriteOffAmount = (this._selectedPo.ServiceAmount - (parseFloat(this.VendorServiceAmount) + parseFloat(this.VendorServiceCGST) + parseFloat(this.VendorServiceSGST))).toString();
                        this._WriteOffFlg = true;
                      }
                    
                      
                    }
                    else {
                      toastOptions.msg += 'Total Tariff Amount should be less than or equal to ' + (TotalPoAmt) + '</br>';
                    }
                

                  }
                  else {
                    toastOptions.msg += 'Total Invoice Amount should be less than or equal to ' + BalanceAmount + '</br>';
                  }

           
    
                }


        
              }
            }
          }
        }

        if (toastOptions.msg != "") {
          this.toastyService.error(toastOptions);
        }
        else {

          if (shortStayFlg) {

            this._displayModal1 = true;
          }
          else {
            //this.ProcessInvoice('direct');
            this._displayModal2 = true;
            this._shortStayFlgTxt = "";
          }

        }

      }
    }
  }

  ProcessInvoice(source) {
    if(source=='No' || source=='Yes'){
      if(source=='Yes') {
        this._shortStayFlgTxt ="true";
        this._displayModal1 = false; 
        this._displayModal2 = true; 
      }
      else {
        debugger
        if(this._selectedPo.ChkOutId !=0) {
          this._shortStayFlgTxt ="false";
          this._displayModal1 = false; 
          this._displayModal2 = true; 
        }
        else {
          this._displayModal1 = false; 
        }
      }
    }
    else if(source=='No Action') {
      this._displayModal2 = false; 
    }
  }


  ProcessInvoiceNew(source) {
    debugger
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 5000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    toastOptions.msg = "";

     
    this._displayModal2 = false; 
  

    // Invoice Validation
    var InvoiceFlg = true;
    var HBGSTFlg = true;
    var HotelGSTFlg = true;

    if (Object.keys(this._InvoiceDetails).length != 0) {
      var HotelNonGSTFlg = false;
      if (this.HotelGstNumber == '' || this.HotelGstNumber == null) {
        HotelNonGSTFlg = true;
      }

      if (HotelNonGSTFlg) {
        if (this.HBGstNumber != this._InvoiceDetails.HB_GST) {
          HBGSTFlg = false;
        }
      }
      else {
        if (this._InvoiceDetails.Hotel_GSTIN == "" || this._InvoiceDetails.Hotel_GSTIN == null || this._InvoiceDetails.Hotel_GSTIN == undefined) {
          HotelGSTFlg = false;
        }
        if (this.HotelGstNumber != this._InvoiceDetails.Hotel_GSTIN) {
          HotelGSTFlg = false;
        }
        if (this.HBGstNumber != this._InvoiceDetails.HB_GST) {
          HBGSTFlg = false;
        }
      }
      if (HotelGSTFlg == false || HBGSTFlg == false) {
        InvoiceFlg = false;
      }
    }
    
    if(HotelGSTFlg==false) {
      this._ExtractionRemarks += "Hotel GSTIN Mismatch. ";
    }
    if(HBGSTFlg==false) {
      this._ExtractionRemarks += "HB GSTIN Mismatch. ";
    }

    // if(InvoiceFlg==false) {
    //   this._ExtractionStatus = "notverified";
    // }

    if (this.TotalServiceInvoiceAmount == "0" || this.TotalServiceInvoiceAmount == null || this.TotalServiceInvoiceAmount == undefined || this.TotalServiceInvoiceAmount == "") {
      this.ServiceFileName = "";
      this.ServiceInvoiceNo = "";
      if (this.VendorServiceAmount == null || this.VendorServiceAmount == undefined || this.VendorServiceAmount == "") {
        this.VendorServiceAmount = "0";
      }
      if (this.VendorServiceCGST == null || this.VendorServiceCGST == undefined || this.VendorServiceCGST == "") {
        this.VendorServiceCGST = "0";
      }
      if (this.VendorServiceSGST == null || this.VendorServiceSGST == undefined || this.VendorServiceSGST == "") {
        this.VendorServiceSGST = "0";
      }
      if (this.TotalServiceInvoiceAmount == null || this.TotalServiceInvoiceAmount == undefined || this.TotalServiceInvoiceAmount == "") {
        this.TotalServiceInvoiceAmount = "0";
      }
    }

    this._ShortStayAmount = "0";
    this._ShortStayFlg = false;
    this._MapPOclose_flag = false;

    if(this._shortStayFlgTxt=='') {
      this._MapPOclose_flag = true;
    }
    if(this._shortStayFlgTxt=='false'){
      this._MapPOclose_flag = false;
      this._WriteOffFlg = false;
      this._WriteOffAmount = "0";
    } 
    else if(this._shortStayFlgTxt=='true'){
      this._MapPOclose_flag = true;
      this._ShortStayAmount = (parseFloat(this._selectedPo.BalanceAmount) - parseFloat(this.TotalInvoiceAmount)).toString();
      this._ShortStayFlg = true;
    } 

    var InvTotalAmount = parseFloat(this.TotalInvoiceAmount);

    var FinalInvoiceAmt = InvTotalAmount;

    var MultipleInvoiceFlg = false;

    if(this.ServiceInvoiceNo !='' && this.ServiceInvoiceNo !=null && this.ServiceInvoiceNo !=undefined) {
      if(this.InvoiceNo != this.ServiceInvoiceNo){
        MultipleInvoiceFlg = true;
      }
    }


    debugger

    var dd = this.InvoiceDate.getDate();
    var mm = this.InvoiceDate.getMonth() + 1;
    var yy = this.InvoiceDate.getFullYear();
    var InvoiceDate = dd + "/" + mm + "/" + yy;

    var SelectedDtls = {
      InvoiceNo: this.InvoiceNo,
      InvoiceDate: InvoiceDate,
      NoofDays: this.NoofDays,
      FilePath: this._tariffFileUrl,
      VendorTariff: this.VendorTariff,
      SGST: this.SGSTAmount,
      CGST: this.CGSTAmount,

      VendorService: this.VendorServiceAmount,
      ServiceCGSTAmount: this.VendorServiceCGST,
      ServiceSGSTAmount: this.VendorServiceSGST,
      TotalInvoiceAmount: InvTotalAmount,

      ServiceInvoiceNo: this.ServiceInvoiceNo,
      ServiceFilePath: this._serviceFileUrl,
      TotalInvoiceAmountTariff: this.TotalTariffInvoiceAmount,
      TotalInvoiceAmountService: this.TotalServiceInvoiceAmount,

      ExtractionStatus: this._ExtractionStatus,

      PropertyRowId: this.RowId,
      SelectedPoDtls: this._selectedPo,
     

      FinalInvoiceAmt: FinalInvoiceAmt,
      FinalPomount: this._selectedPo.POAmount,

      PropertyId: this._HotelId,
      PropertyName: this.HotelName,

      MapPOclose_flag: this._MapPOclose_flag,
      ShortStayFlg: this._ShortStayFlg,
      ShortStayAmount: this._ShortStayAmount,
      WriteOffFlg: this._WriteOffFlg,
      WriteOffAmount: this._WriteOffAmount,
      MultipleInvoiceFlg: MultipleInvoiceFlg,

      ExtractionRemarks: this._ExtractionRemarks,

    }
    return this.http.post("mappingsave", SelectedDtls).toPromise().then(res => {

      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);

      if (parsedJson.message == "Submitted Successfully") {
        toastOptions.msg = "Your Invoice has been submitted successfully, we will verify and update shortly";
        this.toastyService.success(toastOptions);
        this.InvoiceNo = "";
        this.InvoiceDate = new Date();
        this.NoofDays = "";
        this.FileUrl = "";
        this.VendorTariff = "";
        this.VendorServiceAmount = "";
        this.SGSTAmount = "";
        this.CGSTAmount = "";
        this.VendorServiceCGST = "";
        this.VendorServiceSGST = "";
        this.SelectedPoDetails = [];
        this.Orders = [];
        this.rangeDates = this.assigndate;
        this.FileName = "";
        this.TotalTariffInvoiceAmount = "0";
        this.TotalInvoiceAmount = "0";
        this.TotalSelectedPOAmount = "0";

        this.ServiceFileName = "";
        this.ServiceInvoiceNo = "";
        this._ExtractionStatus = "";
        this._MapPOclose_flag = false;
        this.displayModal = false;
        this._tariffFileUrl = "";
        this.TraiffFileName = "";
        this._serviceFileUrl = "";
        this.ServiceFileName = "";

        this._WriteOffAmount = "0";
        this._WriteOffFlg = false;
        this._ShortStayAmount = "0";
        this._ShortStayFlg = false; 
        this._ExtractionStatus = "unverified";
        this._InvoiceDetails = [];
        this._selectedPo = [];

        var mapDtls = {
          FromDt: "",
          ToDt: "",
          RowId: this.RowId,
          Basedon: this.selectedBasedon.selectedBasedon,
          UserId: this.UserId
        }
        setTimeout(() => {
          this.http.post("getbookingdetails", mapDtls).toPromise().then(res => {

            const parseJson = JSON.stringify(res);
            const parsedJson = JSON.parse(parseJson);
            this.Orders = parsedJson.PoDetails[0];
            this.HotelName = parsedJson.PoDetails[1][0].PropertyName;
            this._HotelId = parsedJson.PoDetails[1][0].Id;
            this.HotelGstNumber = parsedJson.PoDetails[1][0].GSTNumber;
            this.HotelAddress = parsedJson.PoDetails[1][0].Propertaddress;

            this.HBName = parsedJson.PoDetails[2][0].CompanyName;
            this.HBGstNumber = parsedJson.PoDetails[2][0].GSTNumber;
            this.HBAddress = parsedJson.PoDetails[2][0].HBAddress;
            this.spinner.hide();
          }).catch(err => {
            this.spinner.hide();
            return err;
          });
        }, 100);
      }
      else if (parsedJson.message == "Invoice Mapped Successfully") {
        toastOptions.msg = "Your Invoice has been mapped successfully.";
        this.toastyService.success(toastOptions);
        this.InvoiceNo = "";
        this.InvoiceDate = new Date();
        this.NoofDays = "";
        this.FileUrl = "";
        this.VendorTariff = "";
        this.VendorServiceAmount = "";
        this.SGSTAmount = "";
        this.CGSTAmount = "";
        this.VendorServiceCGST = "";
        this.VendorServiceSGST = "";
        this.Orders = [];
        this.rangeDates = this.assigndate;
        this.FileName = "";
        this.TotalTariffInvoiceAmount = "0";
        this.TotalInvoiceAmount = "0";
        this.TotalSelectedPOAmount = "0";

        this.ServiceFileName = "";
        this.ServiceInvoiceNo = ""; 
        this._MapPOclose_flag = false;
        this.displayModal = false;
        this._tariffFileUrl = "";
        this.TraiffFileName = "";
        this._serviceFileUrl = "";
        this.ServiceFileName = "";

        this._WriteOffAmount = "0";
        this._WriteOffFlg = false;
        this._ShortStayAmount = "0";
        this._ShortStayFlg = false; 
        this._ExtractionStatus = "unverified";
        this._InvoiceDetails = [];
        this._selectedPo = [];

        var mapDtls = {
          FromDt: "",
          ToDt: "",
          RowId: this.RowId,
          Basedon: this.selectedBasedon.selectedBasedon,
          UserId: this.UserId
        }
        setTimeout(() => {
          this.http.post("getbookingdetails", mapDtls).toPromise().then(res => {

            const parseJson = JSON.stringify(res);
            const parsedJson = JSON.parse(parseJson);
            this.Orders = parsedJson.PoDetails[0];
            this.HotelName = parsedJson.PoDetails[1][0].PropertyName;
            this._HotelId = parsedJson.PoDetails[1][0].Id;
            this.HotelGstNumber = parsedJson.PoDetails[1][0].GSTNumber;
            this.HotelAddress = parsedJson.PoDetails[1][0].Propertaddress;

            this.HBName = parsedJson.PoDetails[2][0].CompanyName;
            this.HBGstNumber = parsedJson.PoDetails[2][0].GSTNumber;
            this.HBAddress = parsedJson.PoDetails[2][0].HBAddress;
            this.spinner.hide();
          }).catch(err => {
            this.spinner.hide();
            return err;
          });
        }, 100);
      }
      else {

        if (parsedJson.message == "Bad Request" || parsedJson.message == "Internal Server Error") {
          toastOptions.msg = "An error occurred while saving the record.";
          this.toastyService.error(toastOptions);
        }
        else if (parsedJson.message == "Invoice number already exists for this property.") {
          toastOptions.msg = "Invoice number already exists for this property.";
          this.toastyService.error(toastOptions);
        }
        else {
          toastOptions.msg = "An error occurred while saving the record.";
          this.toastyService.error(toastOptions);
        }


      }
      this.spinner.hide();
    }).catch(err => {
      this.spinner.hide();
      toastOptions.msg = "An error occurred while saving the record.";
      this.toastyService.error(toastOptions);
      return err;
    });



  }

  MappingClear() 
  {
    this._tariffFileUrl="";
    this.TraiffFileName="";

    this.InvoiceNo = "";
    this.InvoiceDate = new Date();
    this.NoofDays = "";
    this.VendorTariff = "";
    this.SGSTAmount = "";
    this.CGSTAmount = "";
    this.TotalTariffInvoiceAmount = "0";

    this.VendorServiceAmount = "";
    this.VendorServiceCGST = "";
    this.VendorServiceSGST = "";
    this.TotalServiceInvoiceAmount = "0";

    this.TotalInvoiceAmount = "0";

    this._serviceFileUrl = "";
    this.ServiceFileName = "";

    this._WriteOffAmount = "0";
    this._WriteOffFlg = false;
    this._ShortStayAmount = "0";
    this._ShortStayFlg = false;
    this._MapPOclose_flag = false;
    this._ExtractionStatus = "unverified";
    this._InvoiceDetails = []; 
    this.ServiceInvoiceNo ="";
  }

  NoofDaysChange(res, event,index) {
   //alert(event.target.value + ","+ index);
   this.DifferenceAmount = this.TotalInvoiceAmount;
   var DifferenceAmount = 0;

   for(var i=0;i< this.SelectedPoDetails.length;i++)
   {
    if(i == index){
      this.SelectedPoDetails[i].ClacTariff = this.SelectedPoDetails[i].POAmount_PerDay *  event.target.value;
    }

    DifferenceAmount += this.SelectedPoDetails[i].ClacTariff;
   }
    
    this.DifferenceAmount = (parseFloat(this.DifferenceAmount) - parseFloat(DifferenceAmount.toString())).toString();
  }

  TariffChange(event,index) {
    debugger
    this.DifferenceAmount = this.TotalInvoiceAmount;
    var DifferenceAmount = 0;
    for(var i=0;i< this.SelectedPoDetails.length;i++)
    { 
      if(i == index){
      DifferenceAmount += parseFloat(event.target.value.replace(/,/g, ''));
      }
      else {
        DifferenceAmount += parseFloat(this.SelectedPoDetails[i].ClacTariff);
      }
    }
    this.DifferenceAmount = (parseFloat(this.DifferenceAmount) - parseFloat(DifferenceAmount.toString())).toString();
  }

  Modalclose(){ 
    this.MappingClear();
    this._ReservationDetailsflg = false;
    this._Tariffdetailsflg = false;
    this._ServiceDetailsflg = false;
    this._InvoiceDetailsflg = false;
  }
  ShortStay(event,res,index) {
    debugger
  for(var i=0;i< this.SelectedPoDetails.length;i++)
   {
    if(i == index){
      if(event.value=="on") {
        this.SelectedPoDetails[i].StayExtend = false;
      }
      
    }
 
   }

  }
  StayExtend(event,res,index) {
    debugger
    for(var i=0;i< this.SelectedPoDetails.length;i++)
   {
    if(i == index){
      if(event.value=="on") {
        this.SelectedPoDetails[i].ShortStay = false;
      }
      
    }
 
   }
  }
  MappoFinalSave() {
   
      var toastOptions:ToastOptions = {
        title: "Alert", 
        showClose: true,
        timeout: 5000,
        theme: 'material',
        onAdd: (toast:ToastData) => {
        },
        onRemove: function(toast:ToastData) {
        }
    };
    toastOptions.msg="";

     // General validation Start

     // No of days validation
     let noofdays = 0;
     for(var i=0;i< this.SelectedPoDetails.length;i++)
     {
      debugger
      if(this.SelectedPoDetails[i].NoofDays == undefined || this.SelectedPoDetails[i].NoofDays == ''){
            
      }  
      else {
      noofdays += parseInt(this.SelectedPoDetails[i].NoofDays);
      }  
    }

    if(noofdays == 0){
      toastOptions.msg += 'Please select Stay Days' + '</br>';
    }
    else if (noofdays != parseInt(this.NoofDays)){
      toastOptions.msg += 'Total PO Stay Days should be equal to Invoice No. Of Days' + '</br>';
    }

    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
      return false;
    }

    // Difference amount valiadtion
    if(this.DifferenceAmount == "0" || this.DifferenceAmount == undefined && this.DifferenceAmount == ""){
      toastOptions.msg += 'Difference Amount must be zero' + '</br>';
    }

    if (toastOptions.msg != "") {
      this.toastyService.error(toastOptions);
      return false;
    }
    
    // let ErrorTitle = ""; 
    // for(var i=0;i< this.SelectedPoDetails.length;i++)
    // {
    //   if(this.SelectedPoDetails[i].ShortStay == true && this.SelectedPoDetails[i].StayExtend == true){
    //     toastOptions.msg += 'Please select either Short Stay or Extend Stay' + '</br>';
    //   }
    // }





  
      if (toastOptions.msg != "") {
        this.toastyService.error(toastOptions);
      }
      else {

        for(var i=0;i< this.SelectedPoDetails.length;i++)
        {
   
          if(this.SelectedPoDetails[i].ShortStay == true){
   
          }
          else if(this.SelectedPoDetails[i].StayExtend == true){
   
          }
   
           
   
        }
      }

  

  }


  FnUpload(Rowdata) {
    debugger
           this.displayModal = true;
           this.BookingCode = Rowdata.BookingCode;

           // test data 
 


  }
  
  trimInput(event: any) {
    const value = event.target.value;
    if (/^\s/g.test(value)) {
      var value1 = value?.replace(/^\s/g, "");
      event.target.value = value1.trim();
    } 
  }

  Openfile(url: any) {
    const link = document.createElement('a');
    link.href = url;

    // Set the target attribute to '_blank' to open in a new tab
     link.target = '_blank';

    // Trigger a click event on the anchor element
    link.click();
  }



}
