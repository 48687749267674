import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MappingInvoiceComponent } from './mapping-invoice/mapping-invoice.component';
import { PagefooterComponent } from './layout/pagefooter/pagefooter.component';
import { PageheaderComponent } from './layout/pageheader/pageheader.component';
import { PagelayoutComponent } from './layout/pagelayout/pagelayout.component';
import { NgHttpLoaderModule } from 'ng-http-loader'; 
import { ToastyModule } from "ng2-toasty";
import { SpinnerVisibilityService } from 'ng-http-loader';
import {CalendarModule} from 'primeng/calendar';
import {FileUploadModule as PrimeFileUpload } from 'primeng/fileupload';
import {TableModule} from 'primeng/table';
import {ScrollingModule} from '@angular/cdk/scrolling'; 

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';
import { Httpclient } from './_services/authorization.service';
import { AuthenticationService } from './_services/authentication.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';  
import { RouterModule } from '@angular/router';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import {KeyFilterModule} from 'primeng/keyfilter';
// import {DialogModule} from 'primeng-lts/dialog';
import {ButtonModule} from 'primeng/button';
import { BookinghistoryComponent } from './bookinghistory/bookinghistory.component';
import { Error404Component } from './error404/error404.component';
import { DropdownModule } from 'primeng/dropdown';
import { InvoicesComponent } from './invoices/invoices.component';
import { TwoDigitDecimaNumberDirective } from '../app/_directive/two-digit-decima-number.directive';
import { PaymentsComponent } from './payments/payments.component';
import { NoshowComponent } from './noshow/noshow.component';
import {DialogModule} from 'primeng/dialog';
import {InputNumberModule} from 'primeng/inputnumber';

import {AccordionModule} from 'primeng/accordion';
import { NoMinusDirective } from '../app/_directive/no-minus.directive';

import {TooltipModule} from 'primeng/tooltip';
import { ExtranetComponent } from './extranet/extranet.component';

import {SelectButtonModule} from 'primeng/selectbutton';


@NgModule({
  declarations: [
    AppComponent,
    MappingInvoiceComponent,
    PagefooterComponent,
    PageheaderComponent,
    PagelayoutComponent,
    BookinghistoryComponent,
    Error404Component,
    InvoicesComponent,
    TwoDigitDecimaNumberDirective,
    PaymentsComponent,
    NoshowComponent,
    NoMinusDirective,
    ExtranetComponent,
    
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgHttpLoaderModule.forRoot(),
    ToastyModule.forRoot(),
    CalendarModule,
    TableModule,
    PrimeFileUpload,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    KeyFilterModule,
    ButtonModule,
    DropdownModule,
    DialogModule,
    InputNumberModule,
    AccordionModule,
    TooltipModule,
    SelectButtonModule
  ],
  providers: [SpinnerVisibilityService,Httpclient,Location,AuthenticationService],
  bootstrap: [AppComponent],
  exports: [ToastyModule]
})
export class AppModule { }
