import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-pageheader',
  templateUrl: './pageheader.component.html',
  styleUrls: ['./pageheader.component.css']
})

export class PageheaderComponent implements OnInit {

  logindetails: any;
  UserName: string;
  UserId: string;
  UserType: string;
  ClientName: string;
  ClientId: string;
  SelClientName: string;

  public href: string = "";

  
  constructor(
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
debugger
     
    this.href = this.router.url;
    if(this.href=="/")
    {
      $('#dashboards').addClass("active");
      $('#Invoice').removeClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').removeClass("active");
      $('#Payments').removeClass("active");
      $('#NoShow').removeClass("active");
      $('#Inventory').removeClass("active");
      
    }
    else if(this.href == "/bookinghistory")
    {
      $('#dashboards').removeClass("active");
      $('#Invoice').removeClass("active");
      $('#Bookings').addClass("active");
      $('#TotalInvoice').removeClass("active");
      $('#Payments').removeClass("active");
      $('#NoShow').removeClass("active");
      $('#Inventory').removeClass("active");
    }
    else if(this.href == "/invoices")
    {
      $('#dashboards').removeClass("active");
      $('#Invoice').removeClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').addClass("active");
      $('#Payments').removeClass("active");
      $('#NoShow').removeClass("active");
      $('#Inventory').removeClass("active");
    }
    else if(this.href == "/payments"){
      $('#dashboards').removeClass("active");
      $('#Payments').addClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').removeClass("active");
      $('#Invoice').removeClass("active");
      $('#NoShow').removeClass("active");
      $('#Inventory').removeClass("active");
    }
    else if(this.href == "/noshow"){
      $('#dashboards').removeClass("active");
      $('#Payments').removeClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').removeClass("active");
      $('#Invoice').removeClass("active");
      $('#NoShow').addClass("active");
      $('#Inventory').removeClass("active");
    }
    else if(this.href == "/inventory"){
      $('#dashboards').removeClass("active");
      $('#Payments').removeClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').removeClass("active");
      $('#Invoice').removeClass("active");
      $('#Inventory').addClass("active");
      $('#NoShow').removeClass("active");
    }
    else
    {
      $('#dashboards').removeClass("active");
      $('#Invoice').addClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').removeClass("active");
      $('#Payments').removeClass("active");
      $('#NoShow').removeClass("active");
      $('#Inventory').removeClass("active");
      
    }
    window.onclick = function(event) {       
      if (!event.target.matches('.dropbtn')) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
          }
        }
      }
    }


  }
  menuopen() {
     
    //$("#myDropdown").addClass("show");
  }

  logoff() {
    localStorage.clear(); 
     this.router.navigate(['/'])
   
  }

  menuclick(event)
  {
    debugger;
    if(event=="dashboard")
    {
      $('#dashboards').addClass("active");
      $('#Invoice').removeClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').removeClass("active");
      $('#Payments').removeClass("active");
      $('#NoShow').removeClass("active");
      $('#Inventory').removeClass("active");
    }
    else if(event =="billupload")
    {
      var pageName = "billupload";
      $('#dashboards').removeClass("active");
      $('#Invoice').addClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').removeClass("active");
      $('#Payments').removeClass("active");
      $('#NoShow').removeClass("active");
      $('#Inventory').removeClass("active");
      //this.router.navigate([`${pageName}`]);
      //window.location.href = "http://localhost:4200/#/billupload";
    }
    else if(event == "bookings")
    {
      
      $('#dashboards').removeClass("active");
      $('#Invoice').removeClass("active");
      $('#Bookings').addClass("active");
      $('#TotalInvoice').removeClass("active");
      $('#Payments').removeClass("active");
      $('#NoShow').removeClass("active");
      $('#Inventory').removeClass("active");

    }
    else if(event == "invoices")
    {
      $('#dashboards').removeClass("active");
      $('#Invoice').removeClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').addClass("active");
      $('#Payments').removeClass("active");
      $('#NoShow').removeClass("active");
      $('#Inventory').removeClass("active");
    }
    else if(event == "payments"){
      $('#dashboards').removeClass("active");
      $('#Payments').addClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').removeClass("active");
      $('#Invoice').removeClass("active");
      $('#NoShow').removeClass("active");
      $('#Inventory').removeClass("active");
    }
    else if(event == "noshow"){
      $('#dashboards').removeClass("active");
      $('#Payments').removeClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').removeClass("active");
      $('#Invoice').removeClass("active");
      $('#NoShow').addClass("active");
      $('#Inventory').removeClass("active");
    }
    else if(event == "Inventory"){
      $('#dashboards').removeClass("active");
      $('#Payments').removeClass("active");
      $('#Bookings').removeClass("active");
      $('#TotalInvoice').removeClass("active");
      $('#Invoice').removeClass("active");
      $('#NoShow').removeClass("active");
      $('#Inventory').addClass("active");
    }
  }

}
