
import { serialize } from "@angular/compiler/src/i18n/serializers/xml_helper";
import { observable } from "rxjs/symbol/observable";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";
import { Promise } from "q";
import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http'; 

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient) {
    let ticket = localStorage.getItem("_uid");
  }

  login(email: string, password: string) {
    debugger;
    const user = {
      Email: email,
      Password: password
     
    };
    if (user) {
      return this.http.post("login", user).toPromise().then(res => {
        
           
           const parseJson =JSON.stringify(res);
          const parsedJson = JSON.parse(parseJson);
           if(parsedJson.message=="Failure"){
             
            return parseJson;

           }
           else {
             debugger;
            const token = parsedJson.user_token;
            if (token !== undefined) {
              
              localStorage.setItem("_Extranetuid", token);
              localStorage.setItem("Extranetuidusers",JSON.stringify(parsedJson.user_details));
            
            }

           }
          
          
          
        }).catch(err => {
          return err;
        });
    }
  }
  forgetpassword(email: string) {
    debugger;
    const user = {
      Email: email   
     
    };
    if (user) {
      return this.http.post("forgetpassword", user).toPromise().then(res => {
        debugger
        const parseJson =JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
           if(parsedJson.message=="Failure"){
             
            return parseJson;

           }
           else {
            
            const token = parsedJson.user_token;           

           }
          
          
          
        }).catch(err => {
          return err;
        });
    }
  }
  logout() {
    // clear local storage user values
    localStorage.removeItem("_Extranetuid");
    localStorage.removeItem("Extranetuidusers")
  }
}
