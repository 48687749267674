<div class="jumbotron">
<main class="content-wrapper" style="margin-top: 50px;background: jumbotron;border-radius: 8px;"> 
      
    <div class="container-fluid">
    <div class="main_content_container">
        <br> 
        <h4 style="text-align: center;color: black !important;">Welcome {{PropertyName}}</h4>
        <section class="shop-checkouts spacegiven" >
            <div class="container">
                <div class="row g-4">
                    <div class="col-lg-3 col-sm-6 wow fadeInUp"  data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
                        <a class="cat-item4 d-block bg-light text-center rounded p-3"  (click)="menuclick('bookings')" href="/#/bookinghistory">
                            <div class="rounded p-4">
                                <div class="icon mb-3"  style="background: #fff !important;">
                                    <img class="img-fluid" src="https://technext.github.io/makan/img/icon-building.png" alt="Icon">
                                </div><br/>
                                <span class="headdsa">Room Nights</span><br/>
                                <span style="color: #fff;font-size: 15px;">Month of {{CurrentMonth}}</span>
                                <h4 > <span class="timer">{{MonthReservations}}</span></h4>
                               
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-3 col-sm-6 wow fadeInUp"  data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                        <a class="cat-item3 d-block bg-light text-center rounded p-3"  (click)="menuclick('invoices')" href="/#/invoices">
                            <div class="rounded p-4">
                                <div class="icon mb-3"  style="background: #fff !important;">                                   
                                    <img class="img-fluid" src="https://technext.github.io/makan/img/icon-house.png" alt="Icon">
                                </div><br/>
                                <span class="headdsa">Billing</span><br/>
                                <span style="color: #fff;font-size: 15px;">Month of {{CurrentMonth}}</span>
                                <h4 style="color: #fff !important;"> <span class="timer1">{{MonthBilling}}</span><span class="blk"> Lakhs</span></h4>
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;">
                        <a class="cat-item2 d-block bg-light text-center rounded p-3" (click)="menuclick('invoices')" href="/#/invoices">
                            <div class="rounded p-4">
                                <div class="icon mb-3"  style="background: #fff !important;">
                                    <img class="img-fluid" src="https://technext.github.io/makan/img/icon-house.png" alt="Icon">
                                </div><br/>
                                <span class="headdsa">Invoices</span><br/>
                                <span style="color: #fff;font-size: 15px;">Month of {{CurrentMonth}}</span>
                                <h4 > <span class="timer">{{MonthInvoiceMapped}}</span></h4>
                             
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s" style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp;">
                        <a class="cat-item d-block bg-light text-center rounded p-3" (click)="menuclick('invoices')" href="/#/invoices">
                            <div class="rounded p-4">
                                <div class="icon mb-3"  style="background: #fff !important;">
                                    <img class="img-fluid" src="https://technext.github.io/makan/img/icon-housing.png" alt="Icon">
                                </div><br/>
                                <span class="headdsa">Pending Invoices</span><br/>
                                <span style="color: #fff;font-size: 15px;">Month of {{CurrentMonth}}</span>
                                <h4 > <span class="timer">{{MonthInvoiceUnMapped}}</span></h4>
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
                        <a class="cat-item4 d-block bg-light text-center rounded p-3" (click)="menuclick('bookings')" href="/#/bookinghistory">
                            <div class="rounded p-4">
                                <div class="icon mb-3" style="background: #fff !important;">
                                    <img class="img-fluid" src="https://technext.github.io/makan/img/icon-building.png" alt="Icon">
                                </div><br/>
                                <span class="headdsa">Room Nights</span><br/>
                                <span style="color: #fff;font-size: 15px;">year of {{CurrentYear}}</span>
                                <h4 > <span class="timer">{{YearReservations}}</span> </h4>
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                        <a class="cat-item3 d-block bg-light text-center rounded p-3" (click)="menuclick('invoices')" href="/#/invoices">
                            <div class="rounded p-4">
                                <div class="icon mb-3"  style="background: #fff !important;">
                                    <img class="img-fluid" src="https://technext.github.io/makan/img/icon-house.png" alt="Icon">
                                </div><br/>
                                <span class="headdsa">Billing</span><br/>
                                <span style="color: #fff;font-size: 15px;">year of {{CurrentYear}}</span>
                                <h4 style="color: #fff !important;"> <span class="timer1" >{{YearBilling}}</span> <span class="blk"> Lakhs</span></h4>
                                
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;">
                        <a class="cat-item2 d-block bg-light text-center rounded p-3" (click)="menuclick('invoices')" href="/#/invoices">
                            <div class="rounded p-4">
                                <div class="icon mb-3"  style="background: #fff !important;">
                                    <img class="img-fluid" src="https://technext.github.io/makan/img/icon-house.png" alt="Icon">
                                </div><br/>
                                <span class="headdsa">Invoices</span><br/>
                                <span style="color: #fff;font-size: 15px;">year of {{CurrentYear}}</span>
                                <h4 > <span class="timer">{{YearInvoiceMapped}}</span></h4>
                               
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s" style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp;">
                        <a class="cat-item d-block bg-light text-center rounded p-3" (click)="menuclick('invoices')" href="/#/invoices">
                            <div class="rounded p-4">
                                <div class="icon mb-3"  style="background: #fff !important;">
                                    <img class="img-fluid" src="https://technext.github.io/makan/img/icon-housing.png" alt="Icon">
                                </div><br/>
                                <span class="headdsa">Pending Invoices</span><br/>
                                <span style="color: #fff;font-size: 15px;">year of {{CurrentYear}}</span>
                                <h4 > <span class="timer">{{YearInvoiceUnMapped}}</span></h4>
                                
                            </div>
                        </a>
                    </div>
                    
                    <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s" style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp;">
                        <a class="cat-item5 d-block bg-light text-center rounded p-3" (click)="menuclick('bookings')" href="/#/bookinghistory">
                            <div class="rounded p-4">
                                <div class="icon mb-3"  style="background: #fff !important;">
                                    <img class="img-fluid" src="https://technext.github.io/makan/img/icon-housing.png" alt="Icon">
                                </div><br/>
                                <h6>Today Check-In</h6>
                                <h4><span class="timer">{{TodayCheckin}}</span> </h4>
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s" style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp;">
                        <a class="cat-item6 d-block bg-light text-center rounded p-3" (click)="menuclick('bookings')" href="/#/bookinghistory">
                            <div class="rounded p-4">
                                <div class="icon mb-3"  style="background: #fff !important;">
                                    <img class="img-fluid" src="https://technext.github.io/makan/img/icon-housing.png" alt="Icon">
                                </div><br/>
                                <h6>Today Check-Out</h6>
                               
                                <h4><span class="timer">{{TodayCheckout}}</span> </h4>
                            </div>
                        </a>
                    </div>
                </div>
                
          
            </div>
        </section>
    </div>
    </div>
</main>
</div>