import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router,NavigationEnd   } from '@angular/router';
import { ToastyService, ToastyConfig, ToastOptions, ToastData } from 'ng2-toasty';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Httpclient } from '../_services/authorization.service';
import { AuthenticationService } from '../_services/authentication.service';
import { DomSanitizer } from '../../../node_modules/@angular/platform-browser';

import {environment} from '../../environments/environment';

interface Basedon {
  name: string,
  selectedBasedon: string
}

@Component({
  selector: 'app-extranet',
  templateUrl: './extranet.component.html',
  styleUrls: ['./extranet.component.css']
})

export class ExtranetComponent implements OnInit {

  returnUrl: string;
  returnUrlLogin: string;
  private busy: boolean;
  ccode: string;
  _email: string;
  _password: string;

  //_email: string = "poorna@warblerit.com";
  //_password: string ="t";
   //_email: string = "plantrooms.mumbai@gmail.com";
   //_password: string ="Kfy1JcRX"; 


  StartDate: Date;
  EndDate: Date;
  Basedons: Basedon[];
  selectedBasedon: Basedon;

  FromDt: Date;
  ToDt: Date;
  propertyNewid: string;
  UserId: string;
  Propertytype: string;
  htmltextModel: string;
  Result: any[];
  htmltextModelNew: any;
  htmltextModelNew1: any;
  propertyid: string = "";

  maxDate: Date;
  minDate: Date;
  property: boolean = false;
  masterproperty: boolean = false;
  PropertyDtls: any;
  propertyname: string;
  Properties: any;
  Inverntry: boolean = false;
  LoginDes: boolean = true;

  RoomDtlsArray: any;
  EdtDate: string;
  EdtRoomType: string;
  EdtBookingCount: number;
  EdtRoomCount: number;
  EdtId: string;
  DateArray: any;
  PastDte: boolean = false;
  displayDialog2: boolean = false; 
  displayDialog1: boolean = false;

  PFromDt: Date;
  PToDt: Date;
  PmaxDate: Date;
  PminDate: Date;

  minDate2: Date;
  maxDate2: Date; 
  maxDate3: Date;
  minDate3: Date; 

  RoomType: string;
  RoomTypes: any;
  RoomTyprArray: any; 
  AsgnRoomCount: number;
  Sun: string;
  types: any;
  selectedTypes: string[];

  ONBRowid: string;
LoggedIn: boolean = false;
rememberPassword: boolean;

  constructor(
      private route: ActivatedRoute,
      private router: Router ,
      private toastyService: ToastyService,
      private toastyConfig: ToastyConfig,
      private authenticationService: AuthenticationService,
      private http:Httpclient,
      private spinner:SpinnerVisibilityService,
      private sanitizer: DomSanitizer,
      private elem: ElementRef,
    ) {
      this.types = [
        {label: 'Sun', value: 'Sunday'},
        {label: 'Mon', value: 'Monday'},
        {label: 'Tue', value: 'Tuesday'},
        {label: 'Wed', value: 'Wednesday'},
        {label: 'Thu', value: 'Thursday'},
        {label: 'Fri', value: 'Friday'},
        {label: 'Sat', value: 'Saturday'}
    ];

    }
  ngOnInit() {

      //this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/dashboard";
      this.elem.nativeElement.addEventListener('click', (event) => {        
        // Check if the clicked element matches the CSS selector
        if (event.target.matches('.Tcnt.EdtTmpClass')) {
            // Call the function with the clicked element
            this.onClicksdf(event.target);
        }
      });

      var ExtranetUserId = localStorage.getItem('_Extranetuid');
      var ExtranetUserDetls = localStorage.getItem('Extranetuidusers');
      debugger;
      var obj = JSON.parse(ExtranetUserDetls); 
      if(ExtranetUserId != undefined) {
        this.LoginDes = false;
        this.Inverntry = true;
        this.LoggedIn = true;
         this.propertyid = obj.propertyid;
         this.Propertytype = obj.usergroup;
         this.UserId = obj.userid;
         this.FnOnScreenLoad();
      }
  }
logout() {
  
  localStorage.removeItem('_Extranetuid');
  localStorage.removeItem('Extranetuidusers');
  this.LoginDes = true;
  this.Inverntry = false;
  this.LoggedIn = false;

}
  login() {
    this.rememberPassword = true;

    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 3000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };

    if(this._email == undefined || this._email =="") {
      toastOptions.msg='Username is required';
      this.toastyService.error(toastOptions);
      return false;
      }
      else
      {
        if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this._email))) {
          toastOptions.msg='Email is not valid';
          this.toastyService.error(toastOptions);
          return false;
        }
      }
      if(this._password == undefined || this._password =="") {
        toastOptions.msg='Password is required';
          this.toastyService.error(toastOptions);
          return false;
      }
      else {
        this.spinner.show();
         
            const user = {
              Email: this._email,
              Password: this._password
             
            };
            return this.http.post("login", user).toPromise().then(res => {
        
           
              const parseJson =JSON.stringify(res);
             const parsedJson = JSON.parse(parseJson);
              if(parsedJson.message=="Failure"){
                
                toastOptions.msg = 'Invalid Email or Password';
                this.toastyService.error(toastOptions);
                this.spinner.hide();
   
              }
              else {
                debugger;
               const token = parsedJson.user_token;
               if (token !== undefined) {
                 
                 localStorage.setItem("_Extranetuid", token);
                 localStorage.setItem("Extranetuidusers",JSON.stringify(parsedJson.user_details));
                 this.LoginDes = false;
                 this.Inverntry = true;
                 this.LoggedIn = true;
                 this.propertyid = parsedJson.user_details.propertyid;
                 this.Propertytype = parsedJson.user_details.usergroup;
                 this.UserId = parsedJson.user_details.userid;
                 this.FnOnScreenLoad();
                 this.spinner.hide();
               }

               let localstorjval = localStorage.getItem("_Extranetuid");
               if (localstorjval && localstorjval !== "undefined") {
                 debugger
                 this.router.navigate([this.returnUrl]);
               } else {
                 toastOptions.msg = 'Invalid Email or Password';
                 this.toastyService.error(toastOptions);
 
               }
   
              }
             
             
             
           }).catch(err => {
             return err;
           });


      }


  }
  onKeydownUser(event:any){
    //this.password.nativeElement.focus();
  }
  onKeydown(event:any){
    this.login();
  }
   

  onChangeSearch(event:any){

  }
  StartDateSelected(date: any) {

  }
  StartDateSelectedToDte(date: any) {

  }

  onClicksdf(val) {
    debugger
    var Id = val.offsetParent.classList[1]; 
  
    if (Id != undefined) {
        var Details = {
            Id: Id,
            PropertyId: this.propertyid,
            userid: this.UserId
        }
        this.spinner.show();
        this.http.post("RoomTypeDtls", Details).toPromise().then(data => {
            debugger;
            this.spinner.hide();

            const parseJson = JSON.stringify(data);
            const parsedJson = JSON.parse(parseJson);     
            this.RoomDtlsArray = parsedJson.message;

            this.DateArray = parsedJson.message1;

            this.EdtId = Id;
            this.EdtDate = this.RoomDtlsArray[0].Dte;
            this.EdtRoomType = this.RoomDtlsArray[0].RoomType;
            this.EdtBookingCount = this.RoomDtlsArray[0].BookingCount;
            this.EdtRoomCount = this.RoomDtlsArray[0].RoomCount; 
            
            var dt = this.DateArray[0].CurrentDte;

             if(dt <= 0) {
                this.PastDte = false;
             }
             else {
                this.PastDte = true; 
             }
            
            this.displayDialog2 = true;
        }).catch(err => {
            this.spinner.hide();
            return err;
        });          

    }
 

    

}

  FnOnScreenLoad() {  
    this.FromDt = new Date();
    var minDate = new Date();
    minDate.setDate(minDate.getDate() + 6);
    var maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 6);
    this.minDate = maxDate;
    this.maxDate = maxDate;      
    this.ToDt = maxDate;
    debugger
    if (this.Propertytype == "Property") {
        this.property = true;
        this.masterproperty = false; 

        var PropertyDetails = {
            PropertyId: this.propertyid
        }
        this.spinner.show();
        this.http.post("getpropertydetails", PropertyDetails).toPromise().then(data => {
            this.spinner.hide();
            debugger
            const parseJson = JSON.stringify(data);
            const parsedJson = JSON.parse(parseJson);             
            this.PropertyDtls = parsedJson.Data;
            this.propertyname = this.PropertyDtls[0].PropertyName;
            this.ONBRowid = this.PropertyDtls[0].OnboardRowId;
        }).catch(err => {
            this.spinner.hide();
            return err;
        });
    }
    else {
        this.property = false;
        this.masterproperty = true;
        this.propertyNewid = "0";
        var Details = {
            "propertyid": this.propertyid
        }
        this.spinner.show();
        this.http.post("AvailMasterProperty", Details).toPromise().then(data => {
            debugger
            const parseJson = JSON.stringify(data);
            const parsedJson = JSON.parse(parseJson); 
            this.Properties = parsedJson.propertynames;
            this.spinner.hide();
        }).catch(err => {
            this.spinner.hide();
            return err;
        });
    }
  }
  onChange(val) {
    debugger
    if (val == "") {
        this.propertyNewid = "0";
        this.ONBRowid = "";
    }
    else {
        this.propertyNewid = val;
        for(var i=0; i<this.Properties.length; i++) {
            if(this.Properties[i].value == val) {
                this.ONBRowid = this.Properties[i].OnboardRowId;
            }
        } 
    }
    this.htmltextModelNew = "";
    this.htmltextModelNew1 ="";
  }
  onSelect(val) {
    debugger
    this.ToDt = undefined;
    var minDate = new Date(val);
    minDate.setDate(minDate.getDate() + 6);
    var maxDate = new Date(val);
    maxDate.setDate(maxDate.getDate() + 6);
    this.minDate = minDate;
    this.maxDate = maxDate;
    this.ToDt = maxDate;
    this.htmltextModelNew = "";
    this.htmltextModelNew1 ="";
}
onSelect1(Val) {
    this.htmltextModelNew = "";
    this.htmltextModelNew1 ="";
}
  FnSearch() {
    let toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 1000,
      theme: "material",
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
  };
  let Err = "";
  if (this.Propertytype == "Property") {

  }
  else {
      if (this.propertyNewid == "0") {
          Err = Err + "Property Name is required" + "<br />";
      }
  }
  if (this.FromDt == undefined) {
      Err = Err + "From Date is required" + "<br />";
  }
  if (this.ToDt == undefined) {
      Err = Err + "To Date is required" + "<br />";
  }
  if (Err != "") {
      toastOptions.msg = Err;
      this.toastyService.error(toastOptions);
  }
  else {
      debugger
      var propertyid = "";
      if (this.Propertytype == "Property") {
          propertyid = this.propertyid;
      }
      else {
          propertyid = this.propertyNewid;
      }
      var d1 = this.FromDt;
      var d2 = this.ToDt;
      var fromDt1 = d1.getDate() + "/" + (d1.getMonth() + 1) + "/" + d1.getFullYear();
      var todt1 = d2.getDate() + "/" + (d2.getMonth() + 1) + "/" + d2.getFullYear();

      var RoomDetails = {
          PropertyId: propertyid,
          FromDt: fromDt1,
          ToDt: todt1,
          UserId: this.UserId
      }

    //  var RoomDetails = {
    //       PropertyId: 2678,
    //       FromDt: "01/05/2024",
    //       ToDt: "07/05/2024",
    //       userid: 1
    //   }

      this.spinner.show();
      this.http.post("AvailabilityRooms", RoomDetails).toPromise().then(data => {
          this.spinner.hide();

          const parseJson = JSON.stringify(data);
          const parsedJson = JSON.parse(parseJson);
          this.Result = parsedJson.message;
          var RoomFlag = true;
          var RoomNo = "";
          var RoomName = "";
          var thflag = true;
          var bookingCount = true;
          var RoomCount = true;
          debugger
          if(this.Result.length!=0) {
              this.htmltextModel = `<table><thead><tr><th class="RType"><span>Room Type</span></th>`;

              this.htmltextModelNew1 =`<p><span class="Bcnt1">Booking Count</span><span class="Tcnt1" >Room Count</span></p>`;
          }
          else {
              this.htmltextModel = `<br/><br/><div class="CntAlgn"><span>No Records Found<span><br/></div><br/>`;

              this.htmltextModelNew1 = "";
          }

          for (var i = 0; i < this.Result.length; i++) {
              if (this.Result[i].Type == "Date") {
                  var date1 = new Date(this.Result[i].Dt);
                  var month = new Array();
                  month[0] = "Jan";
                  month[1] = "Feb";
                  month[2] = "Mar";
                  month[3] = "Apr";
                  month[4] = "May";
                  month[5] = "Jun";
                  month[6] = "Jul";
                  month[7] = "Aug";
                  month[8] = "Sep";
                  month[9] = "Oct";
                  month[10] = "Nov";
                  month[11] = "Dec";
                  var weekday = new Array(7);
                  weekday[0] = "Sun";
                  weekday[1] = "Mon";
                  weekday[2] = "Tue";
                  weekday[3] = "Wed";
                  weekday[4] = "Thu";
                  weekday[5] = "Fri";
                  weekday[6] = "Sat";
                  var dd = date1.getDate().toString();
                  var mm = date1.getMonth() + 1;
                  var y = date1.getFullYear();
                  var day = weekday[date1.getDay()];
                  var mon = month[date1.getMonth()];
                  if (dd == "1") {
                      dd = "01";
                  }
                  else if (dd == "2") {
                      dd = "02";
                  }
                  else if (dd == "3") {
                      dd = "03";
                  }
                  else if (dd == "4") {
                      dd = "04";
                  }
                  else if (dd == "5") {
                      dd = "05";
                  }
                  else if (dd == "6") {
                      dd = "06";
                  }
                  else if (dd == "7") {
                      dd = "07";
                  }
                  else if (dd == "8") {
                      dd = "08";
                  }
                  else if (dd == "9") {
                      dd = "09";
                  }
                  if (day != "Sun") {
                      this.htmltextModel += ` <th class="thdte"><span class="thday">` + day + `</span><br><span class="thdate">` + dd + `</span> <br><span class="thyear">` + mon + `-` + y + `</span></th>`;
                  }
                  else {
                    this.htmltextModel += ` <th class="thdte"><span class="thday">` + day + `</span><br><span class="thdate">` + dd + `</span> <br><span class="thyear">` + mon + `-` + y + `</span></th>`;
                  }
              }
              else {
                  if (RoomFlag) {
                      this.htmltextModel += `</tr></thead><tbody>`;
                      RoomFlag = false;
                  }
                  if (RoomNo != this.Result[i].RoomType) {

                      if (thflag) {
                          this.htmltextModel += `<tr><th scope="row"><p>` + this.Result[i].RoomType + `</p></th>`;
                          thflag = false;
                      }
                      else {
                          this.htmltextModel += `</tr><tr><th scope="row"><p>` + this.Result[i].RoomType + `</p></th>`;
                          RoomCount = true;
                      }
                      this.htmltextModel += `<td class="tddata `+ this.Result[i].Id + `"><p><span class="Bcnt">` + this.Result[i].BookingCount + `</span><span  class="Tcnt EdtTmpClass" title="Click here to edit">` + this.Result[i].RoomCount + `</span></p><p  class="Acnt">Available -` + this.Result[i].AvailableCount + `</td>`;
                      RoomNo = this.Result[i].RoomType;
                  }
                  else {
                    this.htmltextModel += `<td class="tddata `+ this.Result[i].Id + `"><p><span class="Bcnt">` + this.Result[i].BookingCount + `</span><span  class="Tcnt EdtTmpClass" title="Click here to edit" >` + this.Result[i].RoomCount + `</span></p><p  class="Acnt">Available -` + this.Result[i].AvailableCount + `</td>`;
                    
                  }
              }
          }
          this.htmltextModel += `</tbody></table>`;
          this.htmltextModelNew =this.htmltextModel; 

          
         
          //this.htmltextModelNew =`<a class="checkbox-wrap checkbox-primary mb-0" (click)="forgotPassword()" style="color: #00B98E;">Forgot Password</a><p>sdjgsdf</p>`;

          // this.htmltextModelNew = this.sanitizer.bypassSecurityTrustHtml(this.htmltextModel);
          // this.elem.nativeElement.querySelector('p').addEventListener('click', this.onClicksdf.bind(this));
      }).catch(err => {
          this.spinner.hide();
          return err;
      });
  }

  } 

  fnclose2() {
    this.displayDialog2 = false;
}

fnEdtUpdate() {
    debugger
    let toastOptions: ToastOptions = {
        title: "Alert",
        showClose: true,
        timeout: 1000,
        theme: "material",
        onAdd: (toast: ToastData) => {
        },
        onRemove: function (toast: ToastData) {
        }
    };
    let Err = "";
    if (this.EdtRoomCount == undefined) {
        Err = Err + "Room Count is required" + "<br />";
    }
    else if(this.EdtRoomCount.toString() == "") {
        Err = Err + "Room Count is required" + "<br />";
    }
    else if (this.EdtRoomCount <  this.EdtBookingCount) {
        Err = Err + "Room Count is greater than or equal to " +this.EdtBookingCount+ " <br />";
    }
    if (Err != "") {
        toastOptions.msg = Err;
        this.toastyService.error(toastOptions);
    }
    else {
    var Details = {
        Id:  this.EdtId,
        userid: this.UserId,
        RoomCount: this.EdtRoomCount
    }
    this.spinner.show();
    this.http.post("RoomTypeDtlsUpdate", Details).toPromise().then(data => {
        debugger;
        this.spinner.hide();

        const parseJson = JSON.stringify(data);
        const parsedJson = JSON.parse(parseJson);
         
        if (parsedJson.message.Msg == 'Saved') {
            toastOptions.msg = "Updated successfully";
            this.toastyService.success(toastOptions);
            this.FnSearch();
        }
                   
        this.displayDialog2 = false;
    }).catch(err => {
        this.spinner.hide();
        return err;
    });
}
}

onSelect2(val) {
  this.PToDt = undefined;
  var minDate2 = new Date(val);
  var minDate1 = new Date(val);
  minDate2.setDate(minDate2.getDate() + 1);    
  this.minDate2 = minDate1;    
  this.PToDt = minDate2;
}

Assign() {
  this.RoomType="";

  var propertyid = "";
  if (this.Propertytype == "Property") {
      propertyid = this.propertyid;
  }
  else {
      propertyid = this.propertyNewid;
  }

  const PropDetails = {
      PropertyId: propertyid,
      UserId: this.UserId,
  }
  this.http.post("GetAllRoomtypes", PropDetails).toPromise().then(data => {
      debugger;       
      const parseJson = JSON.stringify(data);
      const parsedJson = JSON.parse(parseJson);     
      this.RoomTyprArray = parsedJson.message;
      this.DateArray = parsedJson.message1;
      //this.PFromDt = this.DateArray[0].CurrentDte;
      //this.PToDt =this.DateArray[0].NewDte;
      var dte= this.DateArray[0].CurrentDte;
      var parts =dte.split('/');
      var fmtdte = parts[2] +"-"+ parts[1] +"-"+ parts[0];
      var mydate = new Date(fmtdte); 
      this.minDate2= mydate;
      this.PFromDt= mydate;
      var dte1= this.DateArray[0].NewDte;
      var parts1 =dte1.split('/');
      var fmtdte1 = parts1[2] +"-"+ parts1[1] +"-"+ parts1[0];
      var mydate1 = new Date(fmtdte1);
      this.PToDt =mydate1;

      var maxDate2 = new Date(mydate);
      maxDate2.setDate(maxDate2.getDate() + 90);
      this.maxDate2 = maxDate2;

      var maxDate3 = new Date(mydate);
      maxDate3.setDate(maxDate3.getDate() + 89);
      this.maxDate3 = maxDate3;

      this.minDate3 = mydate;
      this.AsgnRoomCount = undefined;

      this.displayDialog1 = true;
      this.selectedTypes = ['Sunday'];
      setTimeout(() => {
      this.selectedTypes.push('Monday');
      }, 500);
      setTimeout(() => {
        this.selectedTypes.push('Tuesday');
      }, 600);
      setTimeout(() => {
        this.selectedTypes.push('Tuesday');
      }, 700);
      setTimeout(() => {
        this.selectedTypes.push('Wednesday');
      }, 800);
      setTimeout(() => {
        this.selectedTypes.push('Thursday');
      }, 900);
      setTimeout(() => {
        this.selectedTypes.push('Friday');
      }, 1000);
      setTimeout(() => {
        this.selectedTypes.push('Saturday');
        this.selectedTypes = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
      }, 1100);
      

  }).catch(err => {
      return err;
  });
}

fnAssign() {
  debugger
  
  var propertyid = "";
  if (this.Propertytype == "Property") {
      propertyid = this.propertyid;
  }
  else {
      propertyid = this.propertyNewid;
  }


  let toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 1000,
      theme: "material",
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
  };
  let Err = ""; 
  
  if (this.PFromDt == undefined) {
    Err = Err + "From Date is required" + "<br />";
  }
  if(this.PToDt == undefined) {
      Err = Err + "To Date is required" + "<br />";
  }
  if(this.selectedTypes == undefined || this.selectedTypes.length == 0) {
    Err = Err + "Select days are required" + "<br />";
  }
  if (this.RoomType == undefined || this.RoomType == "") {
      Err = Err + "Room Type is required" + "<br />";
  }
  if (this.AsgnRoomCount == undefined) {
      Err = Err + "Room Count is required" + "<br />";
  } 
  if (Err != "") {
      toastOptions.msg = Err;
      this.toastyService.error(toastOptions);
  }
  else {
      var dt = new Date(this.PToDt);
      var day1 = dt.getDate();
      var month1 = dt.getMonth();
      var year1 = dt.getFullYear();
      var ToDte = day1+"/"+(month1 + 1)+"/"+year1;

      var dt1 = new Date(this.PFromDt);
      var day2 = dt1.getDate();
      var month2 = dt1.getMonth();
      var year2 = dt1.getFullYear();
      var FromDate = day2+"/"+(month2 + 1)+"/"+year2;
      
  var Details = {
      Id:  this.EdtId,
      userid: this.UserId,
      RoomCount: this.AsgnRoomCount,
      FromDate: FromDate,
      RoomType: this.RoomType,
      ToDate: ToDte,
      PropertyId: propertyid,
      SelectedDays: this.selectedTypes
      
  }
  this.spinner.show();
  this.http.post("RoomTypeDtlsAssign", Details).toPromise().then(data => {
      debugger;
      this.spinner.hide(); 
      const parseJson = JSON.stringify(data);
      const parsedJson = JSON.parse(parseJson);     

      if (parsedJson.message.Msg == 'Saved') {
          toastOptions.msg = "Assigned successfully";
          this.toastyService.success(toastOptions);
          this.displayDialog1 = false;
          this.FnSearch();
      }
      else {
          toastOptions.msg = "Room count should be greater than or equal to booking count";
          this.toastyService.error(toastOptions);
      }
                 
    
  }).catch(err => {
      this.spinner.hide();
      return err;
  });
}
}
fnclose() {
  this.displayDialog1 = false;
}

forgotPassword()
  {
    let toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 2000,
      theme: "material",
      onAdd: (toast: ToastData) => {
      },
      onRemove: function(toast: ToastData) {
      }
  };

    if(this._email == undefined || this._email =="")
    {
      toastOptions.msg='Email is required';
      this.toastyService.error(toastOptions);
      return false;
    }
    else
    {
      if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this._email))) {
        toastOptions.msg='Email is not valid';
        this.toastyService.error(toastOptions);
        return false;
      }
    }
    let UserEmail ={
      Email:this._email
    }
    this.spinner.show();
    this.http.post("forgetpassword", UserEmail).toPromise().then(data => {
      this.spinner.hide();
      const parseJson =JSON.stringify(data);
      const parsedJson = JSON.parse(parseJson);
      if(parsedJson.message == "Success")
      {
        toastOptions.msg='Password sent to your Email Successfully';
        this.toastyService.success(toastOptions);
      }
      if(parsedJson.message == "Failure")
      {
        toastOptions.msg='Email not registered with Hummingbird';
        this.toastyService.error(toastOptions);
      }



    }).catch(err => {
     return err;
    });

  }

  FnpropertyDtlsUpdate() {
    let toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 1000,
      theme: "material",
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    let Err = "";
    if (this.Propertytype == "Property") {

    }
    else {
      if (this.propertyNewid == "0") {
        Err = Err + "Property Name is required" + "<br />";
      }
    }
    if (Err != "") {
      toastOptions.msg = Err;
      this.toastyService.error(toastOptions);
    }
    else {
      let OnBoarURL = environment.OnBoarURL + this.ONBRowid;
      window.open(OnBoarURL, '_blank');
    }
  }



}
